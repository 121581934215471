import axios from "axios"
import { useEffect, useState } from "react"
import Toast from "../../components/Toast"


const DisciplinaryCases = () => {

    const [openStates,setOpenStates] = useState([])

    const [disciplines,setDisciplines] = useState([])
    const [disType,setDisType] = useState(0)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/student/disciplines?dis_type=${disType}`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.request.status === 200){
                setOpenStates([])
                setDisciplines(data.data.disciplines)
            }
        })
        .catch(e => {
            if(e.request.status===0){
            Toast('error',e)
            }else if(e.request.status === 404){
                setDisciplines(e.response.data.message)
            }
              
            
        })
    },[disType])


    const handleOpenBox = (id) => {
        setOpenStates((old) => old.find(state => state === id) ? old.filter(state => state !== id) : [...old,id])
    }

    return (
        <>
            <div className=" z-30 sticky top-14 w-full border-t shadow-sm right-0">
                <div className="bg-white flex justify-center items-center p-1 gap-x-1 px-4 my-0 w-full ">
                    <div className="flex items-center gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                        </svg>
                    </div>
                    <span>موارد انضباطی</span>
                </div>
            </div>  
            <div className="flex flex-col gap-y-4 items-center pt-5 px-5 pb-20">
                <div className="flex justify-center overflow-hidden bg-white w-full rounded-xl text-xs border border-gray-300 shadow">
                    <button className={`${disType === 0? 'bg-gray-50':''} py-2.5 cursor-default w-3/12`} onClick={() => setDisType(0)}>همه</button>
                    <div className="border-l border-gray-300"></div>
                    <button className={`${disType === 1? 'bg-gray-50':''} py-2.5 cursor-default w-3/12`} onClick={() => setDisType(1)}>تشویقی ها</button>
                    <div className="border-l border-gray-300"></div>
                    <button className={`${disType === 2? 'bg-gray-50':''} py-2.5 cursor-default w-3/12`} onClick={() => setDisType(2)}>موارد انضباطی</button>
                    <div className="border-l border-gray-300"></div>
                    <button className={`${disType === 3? 'bg-gray-50':''} py-2.5 cursor-default w-3/12`} onClick={() => setDisType(3)}>غیبت ها</button>
                </div>
                    {!Array.isArray(disciplines) && 
                        <div className="bg-white p-2 rounded-md shadow text-center w-full lg:w-4/12">
                            {disciplines}
                        </div>
                    }
                <div className="flex flex-col gap-y-4 w-full">
                    {Array.isArray(disciplines) && disciplines.map(discipline =>(
                        <div key={discipline.id} className="bg-white shadow-md w-full overflow-hidden rounded-xl">
                            <div onClick={() => discipline.description ? handleOpenBox(discipline.id) : ''} className="flex justify-between items-center border-b border-gray-300 py-0.5 bg-gray-50 px-2">
                                <div className="flex gap-x-2 items-center">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                        </svg>
                                    </span>
                                    <span className="text-xs pt-1">{discipline.dis_date}</span>
                                </div>
                            {discipline.description ?
                            <div className="flex justify-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 transition-all duration-300 ${openStates.find((old) => old === discipline.id) ? 'rotate-180' : 'rotate-0'}`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </div>
                            </div>
                            :''}
                            </div>
                            <div className="font-bold p-4 py-2 border-b">
                                {discipline.title} 
                            </div>
                            <div className={`py-2 bg-[#fcfcfc] ${openStates.find((old) => old === discipline.id) ? 'block' :'hidden'}`}>
                                <div className="text-xs px-4 py-2 flex items-center gap-x-2 mb-2">
                                <div className="w-[6.5px] h-1.5 rounded-full bg-black"></div> 
                                <span className="font-bold">{discipline.description}</span>
                                </div>
                                <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                    <span className="text-xs whitespace-nowrap text-gray-600">تصمیم اتخاذ شده</span>
                                    <div className="border-t-2 border-dotted border-gray-300 w-full"></div>
                                    <span className="text-sm whitespace-nowrap">{discipline.decision?'کسر نمره' : 'نمره تشویقی'}</span>
                                </div>
                                <div className="py-0.5 px-4 flex justify-between items-center gap-x-2">
                                    <span className="text-xs whitespace-nowrap text-gray-600">نمره انضباطی</span>
                                    <div className="border-t-2 border-dotted border-gray-300 w-full "></div>
                                    <div className="flex items-center gap-x-1 bg-[#fcfcfc]">
                                        <span className="text-sm">{discipline.score}</span>
                                        {discipline.decision === 0 ? <span className="text-green-600 text-lg rounded-full font-bold">+</span> : <span className="text-red-600 text-2xl rounded-full">-</span> }
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    ))}
                </div>
            </div> 
        </>
    )
}

export default DisciplinaryCases