const Paginate = () => {
  return (
    <div class="flex flex-1 justify-center items-center">
      <a
        class="cursor-default relative inline-flex items-center px-2 py-[7px] text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-r-md leading-5 hover:text-gray-400 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 shadow-sm"
        href="http://127.0.0.1:8000/members?page=2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
        </svg>
      </a>
      <span class="relative inline-flex items-center px-4 py-[7px] -mr-px text-sm font-medium text-gray-400 bg-white border border-gray-300 cursor-default leading-5 shadow-sm">
        3
      </span>
      <a
        class="cursor-default relative inline-flex items-center px-4 py-[7px] -mr-px text-sm font-medium text-gray-600 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 shadow-sm"
        href="http://127.0.0.1:8000/members?page=2">
        2
      </a>
      <a
        class="cursor-default relative inline-flex items-center px-4 py-[7px] -mr-px text-sm font-medium text-gray-600 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 shadow-sm"
        href="http://127.0.0.1:8000/members?page=1">
        1
      </a>
      <span class="relative inline-flex items-center px-2 py-[7px] -mr-px text-sm font-medium text-gray-400 bg-white border border-gray-300 cursor-default rounded-l-md leading-5 shadow-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"></path>
        </svg>
      </span>
    </div>
  );
};

export default Paginate;
