import { useContext, useEffect, useRef, useState } from 'react'
import AbedibiImage from '../../images/login-title.jpg'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { LoginContext } from '../../components/Context/LoginContext'
import { AdminContext } from '../../components/Context/AdminContext'

const Login = () => {

    const elementRef = useRef()
    const [activeClass,setActiveClass] = useState({
        username : false,
        password : false
    })

    const [error,setError] = useState({})

    const [nationCode,setNationCode] = useState(null)
    const [password,setPassword] = useState(null)

    const navigator = useNavigate()

    const {isLogin,setIsLogin} = useContext(LoginContext)
    const {adminPath} = useContext(AdminContext)

    useEffect(() => {
        if(isLogin)
            navigator(`/${adminPath}`)
        elementRef.current.focus()
    },[])

    const handleLogin = () => {
        if(password && nationCode)
        axios.post(`${process.env.REACT_APP_AddressIP}/api/admin-login`,{
            username : nationCode,
            password
        },{
            headers : {
                Accept : 'application/json',
                'Content-Type' : 'application/json'
            }
        }).then((data) => {
            localStorage.setItem('token',data.data.token)
            setIsLogin(true)
            navigator('/admin')
        }).catch(err => {   
            setError(null)  
            if(err.response.data.code === 401)
                setError({message : err.response.data.message,hasNationCode : true,hasPassword : true})
                if(err.response.data.errors && err.response.data.errors.username )
                 setError((old) => ({...old,nationCode : err.response.data.errors.username[0],hasNationCode:true}))
                if(err.response.data.errors && err.response.data.errors.password )
                setError((old) => ({...old,password : err.response.data.errors.password[0],hasPassword : true}))
        })
        else 
        setError({message : 'رمز عبور و نام کاربری  نباید خالی باشد',hasNationCode : true,hasPassword : true})
    }

    const handleKeyUp = (e) => {
        if(e.key === 'Enter')
            handleLogin()
    }

    return (
        <>
            <div className=" flex flex-col gap-y-4 justify-center items-center pt-10 outline-none" ref={elementRef} tabIndex={0} onKeyUp={(e) => handleKeyUp(e)}>
                <div className='relative bg-blue-400  w-11/12 max-w-xs sm:w-6/12 sm:max-w-xs md:w-11/12 md:max-w-sm  rounded-lg shadow-md overflow-hidden'>
                    <div className="bg-white rounded-lg shadow-md z-20">
                        <div className='pb-10'>
                            <img src={AbedibiImage} className='object-cover w-full h-28' alt="" />
                        </div>
                        <div className='px-4'>
                            <div className={`border  w-full p-2 rounded-md flex gap-x-2 justify-between items-center mb-2 ${error.hasNationCode ? 'border-red-500' : 'border-gray-300'} ${activeClass.username ? 'border-sky-400' : ''}`}>
                                <input onChange={(e) => setNationCode(e.target.value)} onFocus={() => setActiveClass({
                                    username : true
                                })} type="text"  placeholder="نام کاربری" className="w-full text-gray-600 text-sm outline-none placeholder:text-gray-300"/>
                                <span>
                                    <i className="fa-lg text-sky-400 fa-solid fa-user"></i>
                                </span>
                            </div>
                            <div className={`border border-gray-300 w-full p-2 rounded-md flex gap-x-2 justify-between items-center mb-5 ${error.hasPassword ? 'border-red-500' : 'border-gray-300'} ${activeClass.password ? 'border-sky-400' : ''}`}>
                                <input onChange={(e) => setPassword(e.target.value)} onFocus={() => setActiveClass({
                                    password : true
                                })} type="password"  placeholder="کلمه عبور" className="w-full text-gray-600 text-sm outline-none placeholder:text-gray-300"/>
                                <span>
                                    <i className="fa-lg text-sky-400 fa-solid fa-lock"></i>
                                </span>
                            </div>
                        </div>
                        <div className='flex justify-center items-center px-4'>
                            <input type="button" onClick={handleLogin} onFocus={() => setActiveClass({
                                username : false ,
                                password : false
                            })} value="ورود به سامانه" className="cursor-default focus:bg-sky-600 w-full mx-auto bg-sky-400 text-white py-2 rounded-md text-sm font-bold"/>
                        </div>

                        <div className='text-red-600 mt-5 px-4 flex flex-col gap-y-2 items-center justify-center'>
                            {error.nationCode &&
                            <div className='text-xs flex gap-x-2'>
                                <span>
                                    <i className="fa-sharp fa-lg fa-solid fa-circle-exclamation"></i>
                                </span>
                                <span>{error.nationCode}</span>
                            </div>
                            }
                            {error.password &&
                            <div className='text-xs flex gap-x-2'>
                                <span>
                                    <i className="fa-sharp fa-lg fa-solid fa-circle-exclamation"></i>
                                </span>
                                <span>{error.password}</span>
                            </div>
                            }
                            {error.message &&
                            <div className='text-xs flex gap-x-2 pb-5'>
                                <span>
                                    <i className="fa-sharp fa-lg fa-solid fa-circle-exclamation"></i>
                                </span>
                                <span>{error.message}</span>
                            </div>
                            }
                        </div>    
                    </div>   
                </div>
            </div>
        </>
    )
}

export default Login