import axios from "axios"
import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router"
import Swal from "sweetalert2"
import { AdminContext } from "../../../components/Context/AdminContext"

const EditNews = () => {
    const {adminPath,setBlurFiles} = useContext(AdminContext)
    const fileUpload = useRef()
    const ImageUpload = useRef()
    const navigate = useNavigate()
    const {id} = useParams()
    const [title,setTitle] = useState(null)
    const [content,setContent] = useState(null)
    const [showFiles,setShowFiles] = useState(false)
    const [active,setActive] = useState(false)
    const [errors,setErrors] = useState({
        title:null,
        content:null,
        image:null
    }) 
    const [image,setImage] = useState(null)
    const [files,setFiles] = useState([])
    const imageType = ['jpg','png','jpeg']
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/news/${id}`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            setTitle(data.data.news.title)
            setContent(data.data.news.content)
            setImage(data.data.news.news_pic)
            setActive(data.data.news.active)
            if(data.data.news.attachments!==null)
                setFiles(data.data.news.attachments.split('|'))
        })
    },[])

    const attachments = (e) => {
        setErrors({image:null})
        const file = e.currentTarget.files[0]
        axios.post(`${process.env.REACT_APP_AddressIP}/api/admin/news/upload-image`,{
            image : file
        },{
            headers : {
                'content-type': 'multipart/form-data',
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(data => setFiles((old) => [...old,data.data.replace('localhost',process.env.REACT_APP_IP)]))
        .catch(e => {
            if(e.response && e.response.status === 422)
            {
                setErrors({...e.response.data.errors})
            }
        })
    }

    const uploadImage = (e) => {
        axios.post(`${process.env.REACT_APP_AddressIP}/api/admin/news/upload-image`,{
            image : e.currentTarget.files[0]
        },{
            headers : {
                'content-type': 'multipart/form-data',
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(data => setImage(data.data.replace('localhost',process.env.REACT_APP_IP)))

    }

    const editNews = () => {
        axios.patch(`${process.env.REACT_APP_AddressIP}/api/admin/news/${id}`,{
            title,
            content,
            news_pic : image,
            active,
            attachments : files.join('|')
        },{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.data.code === 200)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#afffcc',
                    position: 'top-end',
                    iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-500"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',                    
                    showConfirmButton: false,
                    timer: 3000,
                    customClass : {
                        icon : 'border-0'
                    },
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                  
                  Toast.fire({
                    title: data.data.message
                  })
                  navigate(`/${adminPath}/news`)
            }
        }).catch(e => {
            if(e.request.status === 422)
            {
                setErrors({...e.response.data.errors})
            }else if(e.request.status === 0)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#dc2626',
                    position: 'top-end',
                    iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" /></svg>',
                    color : 'white',
                    showConfirmButton: false,
                    timer: 3000,
                    customClass:{
                        icon:'border-0'
                    },
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                  
                  Toast.fire({
                    title: 'خطا در برقراری ارتباط با سرور'
                  })
            }
        })
    }

    return (
        <div className="w-full flex justify-center pb-20 pt-5">
            <div className="bg-white lg:w-6/12 w-10/12 lg:max-w-2xl shadow rounded-xl flex flex-col gap-2 p-4 relative">
                <div className="flex flex-col gap-y-2 lg:flex-row gap-x-2">
                    <div className="flex flex-col w-full">
                        <div>
                            <input type="text" defaultValue={title} onChange={(e) => setTitle(e.target.value)} placeholder="عنوان خبر"  className={` border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                        </div>
                        {errors.title &&
                            <div className="text-sm text-red-600 flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.title}</span>
                            </div>
                        }
                        <textarea onChange={(e) => setContent(e.target.value)} defaultValue={content} placeholder="محتوای خبر ..." className={`col-start-1 resize-none border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full h-44`}/>
                        {errors.content &&
                            <div className="text-sm text-red-600 flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.content}</span>
                            </div>
                        }
                    </div>
                    <div className="flex flex-col w-full gap-y-2">
                        <div className="w-full flex justify-center">
                            <img src={image} alt="" className="rounded-lg h-20 w-20" />
                        </div>
                        <button onClick={() => ImageUpload.current.click()} className="cursor-default lg:cursor-pointer flex items-center border border-sky-400 p-2 rounded-lg">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
                                </svg>
                            </span>
                            <span className="text-sm">آپلود عکس شاخص</span>
                        </button>
                        {errors.news_pic &&
                            <div className="text-sm text-red-600 flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.news_pic}</span>
                            </div>
                        }
                        <input className="hidden" onChange={(e) => uploadImage(e)} type="file" ref={ImageUpload} />
                        <button onClick={() => fileUpload.current.click()} className="lg:cursor-pointer cursor-default flex items-center border border-sky-400 p-2 rounded-lg">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
                                </svg>
                            </span>
                            <span className="text-sm">آپلود فایل</span>
                        </button>
                        {errors.image &&
                            <div className="text-sm text-red-600 flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.image}</span>
                            </div>
                        }
                        {files.length > 0 && 
                            <button className="text-sky-400 cursor-default lg:cursor-pointer col-start-2" onClick={() => {
                                setShowFiles(true)
                                setBlurFiles(true)
                            }}>
                                <span>{files.length}</span>
                                <span className="mr-1">فایل</span>
                            </button>
                        }
                        <div className={`${showFiles?'':'hidden'} z-30 disableBlur absolute top-20 right-0 w-full flex justify-center`}>
                            <div className=" bg-white lg:w-8/12 border p-4 rounded-lg w-full">
                                <div>
                                    <button className="cursor-default lg:cursor-pointer" onClick={() => {
                                        setBlurFiles(false)
                                        setShowFiles(false)
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                    <div className="text-center pb-2">فایل ها</div>
                                </div>
                                <div className="grid grid-cols-3 gap-4">
                                {files && files.map(file => (
                                    <div key={file}>
                                        {imageType.includes(file.split('/').pop().split('.').pop()) ?
                                        <img src={file} className="rounded-md h-20 w-full object-cover" alt="" /> : 
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                            </svg>
                                        
                                        }
                                        <button className="bg-red-500 w-full p-1 rounded-md mt-2 flex text-xs justify-center text-white">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                </svg>
                                            </span>
                                            <span>حذف</span>
                                        </button>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                        <input className="hidden" onChange={(e) => attachments(e)} type="file" ref={fileUpload} />
                        <div className="flex items-center gap-x-2">
                            <span>فعال</span>
                            <button type="button" onClick={() => setActive(!active)} class={`${active ? 'bg-blue-500' : 'bg-gray-200'} cursor-default flex w-8 flex-none lg:cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" role="switch" aria-checked="false" aria-labelledby="switch-1-label`}>
                                <span aria-hidden="true" class={`${active ? 'translate-x-0' : '-translate-x-3.5'} h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out`}></span>
                            </button>
                            <span>غیر فعال</span>
                        </div>
                        <div className="flex justify-end z-0">
                            <button onClick={editNews} className=" cursor-default lg:cursor-pointer bg-sky-400 px-4 py-2 rounded-md text-white shadow hover:bg-sky-600">بروزرسانی</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditNews