import { useContext, useState } from "react"
import ErorrForm from "../../../components/Main/ErorrForm"
import Calendar from "../../../components/Main/Calendar"
import axios from "axios"
import { useNavigate } from "react-router"
import Swal from 'sweetalert2'
import { AdminContext } from "../../../components/Context/AdminContext"

const CreateStudent = () => {

    const navigate = useNavigate()

    const [activeMenu,setActiveMenu] = useState(1)
    const [loading,setLoading] = useState(false)
    const [fields,setFields] = useState({
        firstName : '',
        lastName : '',
        fatherName : '',
        nationCode : '',
        birthday : '',
        bornPlace : '',
        nationality : '',
        religion : '',
        specialAbility : '',
        rank : '',
        specialDisease : '',
        leftHand : false,
        grade : '',
        field : '',
        school : '',
        average : '',
        disciplineGrade : '',
        phone : '',
        fatherPhone : '',
        motherPhone : '',
        smsPhone : '',
        homePhone : '',
        emergencyPhone : '',
        address : '',
        homeState : '',
        postalCode : '',
        service : false,
        forum : false,
        fatherDead : false ,
        fatherEducation : '',
        fatherJob : '',
        fatherJobAddress : '',
        motherDead : false ,
        motherEducation : '',
        motherJob : '',
        motherJobAddress : '',
        divorced : false,
        lifeWith : '',
        brothers : 0,
        sisters : 0,
        coveredBy : '',
        sacrifice : '',
        password : '',
        rePassword : ''
    })
    const {adminPath} = useContext(AdminContext)
    const [errorStudentInformation,setErrorStudentInformation] = useState(0)
    const [errorEducationInformation,setErrorEducationInformation] = useState(0)
    const [errorContactInformation,setErrorContactInformation] = useState(0)
    const [errorFamilyInformation,setErrorFamilyInformation] = useState(0)
    const [errorUserInformation,setErrorUserInformation] = useState(0)
    const [errors,setErrors] = useState({
        hasError : false,
        firstName : '',
        lastName : '',
        fatherName : '',
        nationCode : '',
        birthday : '',
        bornPlace : '',
        nationality : '',
        religion : '',
        specialAbility : '',
        rank : '',
        specialDisease : '',
        grade : '',
        field : '',
        school : '',
        average : '',
        disciplineGrade : '',
        phone : '',
        fatherPhone : '',
        motherPhone : '',
        smsPhone : '',
        homePhone : '',
        emergencyPhone : '',
        address : '',
        homeState : '',
        postalCode : '',
        fatherEducation : '',
        fatherJob : '',
        fatherJobAddress : '',
        motherEducation : '',
        motherJob : '',
        motherJobAddress : '',
        lifeWith : '',
        brothers : '',
        sisters : '',
        coveredBy : '',
        sacrifice : '',
        password : '',
        rePassword : ''
    })
        
    const fatherDeadHandle = (e) => {
        if (e.target.checked)
            setFields({
                ...fields,
                fatherDead : true,
                fatherEducation : '',
                fatherJob : '',
                fatherJobAddress : ''
            })       
        else
            setFields({...fields,fatherDead:false})
    }

    const motherDeadHandle = (e) => {
        if (e.target.checked)
            setFields({
                ...fields,
                motherDead : true,
                motherEducation : '',
                motherJob : '',
                motherJobAddress : ''
            })       
        else
            setFields({...fields,motherDead:false})
    }

    const handleDivorced = (e) => {
        setFields({divorced : e.target.checked})
        if (!e.target.checked)
        setFields({lifeWith : ''})
    }

    const handleFields = (e,field) => {
        if(e.target.type!=='checkbox')
        setFields({...fields,[field] : e.target.value})
        else if(e.target.type==='checkbox' && e.target.checked)
        setFields({...fields,[field] : 1})
        else
            setFields({...fields,[field] : 0})
    }

    const handleSubmitForm = () => {
        setLoading(true)
        // setErrors({...errors,
        //     password : '',
        //     rePassword : ''
        // })
        if(fields.password !== fields.rePassword)
        {
            setErrors({...errors,
                hasError:true,
                password : 'رمز عبور با تکرار رمز عبور برابر نیست',
                rePassword : 'رمز عبور با تکرار رمز عبور برابر نیست',
            })
            
            setLoading(false)
        }
        axios.post(`${process.env.REACT_APP_AddressIP}/api/admin/management-users`,{
            first_name : fields.firstName,
            last_name : fields.lastName,
            father_name : fields.fatherName,
            nation_code : fields.nationCode,
            birthday : fields.birthday===''? '1402/02/15' : fields.birthday,
            born_place : fields.bornPlace,
            nationality : fields.nationality,
            religion : fields.religion,
            left_hand : fields.leftHand,
            special_ability : fields.specialAbility,
            rank : fields.rank,
            special_disease : fields.specialDisease,
            grade : fields.grade,
            field : fields.field,
            school : fields.school,
            average : fields.average,
            discipline_grade : fields.disciplineGrade,
            phone : fields.phone,
            father_phone : fields.fatherPhone,
            mother_phone : fields.motherPhone,
            sms_phone : fields.smsPhone,
            home_phone : fields.homePhone,
            emergency_phone : fields.emergencyPhone,
            address : fields.address,
            home_state : fields.homeState,
            postal_code : fields.postalCode,
            service : fields.service,//
            forum : fields.forum,//
            father_dead : fields.fatherDead,
            mother_dead : fields.motherDead,
            father_education : fields.fatherEducation,
            father_job_address : fields.fatherJobAddress,
            father_job : fields.fatherJob,
            mother_education : fields.motherEducation,
            mother_job_address : fields.motherJobAddress,
            mother_job : fields.motherJob,
            divorced : fields.divorced,
            life_with : fields.lifeWith,
            brothers : fields.brothers,
            sisters : fields.sisters,
            covered_by : fields.coveredBy,
            sacrifice : fields.sacrifice
        },{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.data.code === 200)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#afffcc',
                    position: 'top-end',
                    iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-500"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',                    
                    customClass : {
                        icon : 'border-0'
                    },
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                  
                  Toast.fire({
                    title: data.data.message
                  })
                navigate(`/${adminPath}/students`)
            }
        })
        .catch(e => {
            setErrorStudentInformation(0);
            setErrorEducationInformation(0)
            setErrors({})
            // eslint-disable-next-line array-callback-return
            setLoading(false)
            if(e.request.status === 403)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : 'red',
                    position: 'top-end',
                    color:'white',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                  
                  Toast.fire({
                    title: e.response.data.message
                  })
            }
            if(e.request.status===422)
            {
                setErrors((oldErrors) => ({
                    ...oldErrors,
                    hasError : true,
                    firstName : e.response.data.errors.first_name,
                    lastName : e.response.data.errors.last_name,
                    fatherName : e.response.data.errors.father_name,
                    nationCode : e.response.data.errors.nation_code,
                    birthday : e.response.data.errors.birthday,
                    bornPlace : e.response.data.errors.born_place,
                    nationality : e.response.data.errors.nationality,
                    religion : e.response.data.errors.religion,
                    specialAbility : e.response.data.errors.special_ability,
                    rank : e.response.data.errors.rank,
                    specialDisease : e.response.data.errors.special_disease,
                    grade : e.response.data.errors.grade,
                    field : e.response.data.errors.field,
                    school : e.response.data.errors.school,
                    average : e.response.data.errors.average,
                    disciplineGrade : e.response.data.errors.discipline_grade,
                    phone : e.response.data.errors.phone,
                    fatherPhone : e.response.data.errors.father_phone,
                    motherPhone : e.response.data.errors.mother_phone,
                    smsPhone : e.response.data.errors.sms_phone,
                    homePhone : e.response.data.errors.home_phone,
                    emergencyPhone : e.response.data.errors.emergency_phone,
                    address : e.response.data.errors.address,
                    homeState : e.response.data.errors.home_state,
                    postalCode : e.response.data.errors.postal_code,
                    fatherEducation : e.response.data.errors.father_education,
                    fatherJob : e.response.data.errors.father_job,
                    fatherJobAddress : e.response.data.errors.father_job_address,
                    motherEducation : e.response.data.errors.mother_education,
                    motherJob : e.response.data.errors.mother_job,
                    motherJobAddress : e.response.data.errors.mother_job_address,
                    lifeWith : e.response.data.errors.life_with,
                    brothers : e.response.data.errors.brothers,
                    sisters : e.response.data.errors.sisters,
                    coveredBy : e.response.data.errors.covered_by,
                    sacrifice : e.response.data.errors.sacrifice,
                }))
            }
        })

    }

    const handleActiveMenu = (menu) => {
        setActiveMenu(activeMenu === menu ? 0 : menu)
    }

    return (
        <div className="p-4 xl:p-10 pb-16">
            {/* <div className="fixed top-8 right-8 px-5 py-3 text-white bg-green-600 z-30">
                <div>ثبت نام با موفقیت انجام شد</div>
            </div> */}
            <div onClick={() => handleActiveMenu(1)} className={`cursor-pointer bg-white p-6 rounded-tr-md rounded-tl-md flex justify-between items-center transition-all ${activeMenu === 1 ? 'shadow-md' : 'rounded-b-md delay-[600ms] shadow-sm'}`}>
                <span className="font-bold xl:text-xl text-base">
                    <span>
                        اطلاعات هنرجو
                    </span>
                    {errorStudentInformation !== 0  ? <span className="bg-red-500 mr-2 rounded-full px-2 text-white font-medium text-base">{errorStudentInformation}</span> : ''}
                </span>
                <span>
                    <i className={`fa-solid fa-chevron-down fa-xs transition-all duration-700 ${activeMenu === 1 ? 'rotate-180' : ''}`}></i>
                </span>
            </div>
            <div className={`relative bg-white shadow-md border-t transition-all overflow-hidden duration-700 rounded-b-md ${activeMenu === 1 ?  `${errors.hasError ? 'h-[93rem] md:h-[53rem] xl:h-[42rem]' : 'h-[74rem] md:h-[43rem] xl:h-[36rem]'} pb-5 xl:pb-0` : 'h-0 border-t-0'}`}>
                <div className="grid lg:grid-cols-3  md:grid-cols-2 gap-x-4 grid-cols-1 justify-items-center xl:px-11 px-4 gap-y-6 pt-10 text-sm xl:text-base">
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>نام</span>
                                <span className="text-red-600">*</span>
                            </div>
                            <input onChange={(e) => handleFields(e,'firstName')} type="text" className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm error={errors.firstName} group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>نام خانوادگی</span>
                                <span className="text-red-600">*</span>
                            </div>
                            <input type="text" onChange={(e) => handleFields(e,'lastName')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm error={errors.lastName}  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation}/>
                        </div>
                    </div>
                     <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>نام پدر</span>
                            </div>
                            <input type="text" onChange={(e) => handleFields(e,'fatherName')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm error={errors.fatherName}  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>شماره ملی</span>
                                <span className="text-red-600">*</span>
                            </div>
                            <input type="number" onChange={(e) => handleFields(e,'nationCode')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm error={errors.nationCode} group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center relative">
                            <div className="w-full mb-2">
                                <span>تاریخ تولد</span>
                            </div>
                            <Calendar setFields={setFields}/>
                            <ErorrForm  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation} error={errors.birthday}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>محل تولد</span>
                            </div>
                            <input type="text" onChange={(e) => handleFields(e,'bornPlace')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation} error={errors.bornPlace}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>ملیت</span>
                            </div>
                            <select onChange={(e) => handleFields(e,'nationality')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md ">
                                <option value="1">ملیت خود را انتخاب کنید</option>
                                <option value="ایرانی">ایرانی</option>
                                <option value="افغانی">افغانی</option>
                                <option value="سایر">سایر</option>
                            </select>
                            <ErorrForm  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation} error={errors.nationality}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>دین و مذهب</span>
                            </div>
                            <select onChange={(e) => handleFields(e,'religion')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md ">
                                <option value="">دین مذهب خود را انتخاب کنید</option>
                                <option value="اسلام شیعه">اسلام شیعه</option>
                                <option value="اسلام اهل سنت">اسلام اهل سنت</option>
                                <option value="مسیحی">مسیحی</option>
                                <option value="فرقه بهاییت">فرقه بهاییت</option>
                                <option value="سایر">سایر</option>
                            </select>
                            <ErorrForm  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation} error={errors.religion}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">بیماری خاص</div>
                            <input type="text" onChange={(e) => handleFields(e,'specialDisease')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation} error={errors.specialDisease}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">توانمندی خاص</div>
                            <input type="text" onChange={(e) => handleFields(e,'specialAbility')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation} error={errors.specialAbility}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">کسب مقام</div>
                            <input type="text" onChange={(e) => handleFields(e,'rank')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm  group={'studentInformation'} setErrorStudentInformation={setErrorStudentInformation} error={errors.rank}/>
                        </div>
                    </div>
                    <div className="hidden xl:block"></div>
                    <div className="w-full">
                        <div className="flex justify-start items-center gap-x-2 pr-2">
                            <input type="checkbox" onChange={(e) => handleFields(e,'laftHand')}/>
                            <div className="">چپ دست</div>
                        </div>
                    </div> 
                </div>
                <div className="xl:flex justify-end xl:pl-16 px-4 absolute bottom-5 w-full left-0">
                    <button onClick={() => handleActiveMenu(2)} className="flex gap-x-2 justify-center bg-cyan-400 mt-5 px-4 py-2 rounded-md text-white w-full xl:w-auto disabled:opacity-50 disabled:cursor-not-allowed">
                        <span className="">
                            مرحله بعد
                        </span>
                        
                    </button>
                </div>
            </div>      
            <div onClick={() => handleActiveMenu(2)} className={`mt-5 cursor-pointer bg-white p-6 rounded-tr-md rounded-tl-md flex justify-between items-center transition-all ${activeMenu === 2 ? 'shadow-md' : 'rounded-b-md delay-[600ms] shadow-sm'}`}>
                <span className="font-bold xl:text-xl text-base">
                    <span>اطلاعات تحصیلی</span>
                    {errorEducationInformation !== 0  ? <span className="bg-red-500 mr-2 rounded-full px-2 text-white font-medium text-base">{errorEducationInformation}</span> : ''}
                </span>
                <span>
                    <i className={`fa-solid fa-chevron-down fa-xs transition-all duration-700 ${activeMenu === 2 ? 'rotate-180' : ''}`}></i>
                </span>
            </div>
            <div className={`relative bg-white shadow-md border-t transition-all overflow-hidden duration-700 rounded-b-md ${activeMenu === 2 ? `${errors.hasError ? 'h-[45rem] md:h-[31rem] xl:h-[22rem]' : 'h-[36.5rem] md:h-[26rem] xl:h-[19rem]'} pb-5 xl:pb-0` : 'h-0 border-t-0'}`}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-x-4 grid-cols-1 justify-items-center xl:px-11 px-4 gap-y-6 pt-10 text-sm xl:text-base">
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>پایه</span>
                                <span className="text-red-600">*</span>
                            </div>
                            <select onChange={(e) => handleFields(e,'grade')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md ">
                                <option value="0">پایه خود را انتخاب کنید</option>
                                <option value="دهم">دهم</option>
                                <option value="یازدهم">یازدهم</option>
                                <option value="دوازدهم">دوازدهم</option>
                            </select>
                            <ErorrForm group={'educationInformation'} setErrorEducationInformation={setErrorEducationInformation} error={errors.grade}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>رشته</span>
                                <span className="text-red-600">*</span>
                            </div>
                            <select onChange={(e) => handleFields(e,'field')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md ">
                                <option value="0">رشته خود را انتخاب کنید</option>
                                <option value="شبکه و نرم افزار رایانه">شبکه و نرم افزار رایانه</option>
                                <option value="نقشه کشی معماری">نقشه کشی معماری</option>
                                <option value="حسابداری">حسابداری</option>
                                <option value="فوتوگرافیک">فوتوگرافیک</option>
                                <option value="پویا نمایی">پویا نمایی</option>
                            </select>
                            <ErorrForm group={'educationInformation'} setErrorEducationInformation={setErrorEducationInformation} error={errors.field}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>مدرسه سال نهم</span>
                                <span className="text-red-600">*</span>
                            </div>
                            <input type="text" onChange={(e) => handleFields(e,'school')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'educationInformation'} setErrorEducationInformation={setErrorEducationInformation} error={errors.school}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>معدل</span>
                                <span className="text-red-600">*</span>
                            </div>
                            <input type="number" onChange={(e) => handleFields(e,'average')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'educationInformation'} setErrorEducationInformation={setErrorEducationInformation} error={errors.average}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>انضباط</span>
                                <span className="text-red-600">*</span>
                            </div>
                            <datalist id="disciplineGrade">
                                <option value="عالی"/>
                                <option value="خیلی خوب"/>
                                <option value="خوب"/>
                            </datalist>
                            <input type="text" list="disciplineGrade" placeholder="مثال:18.35,عالی" onChange={(e) => handleFields(e,'disciplineGrade')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'educationInformation'} setErrorEducationInformation={setErrorEducationInformation} error={errors.disciplineGrade}/>
                        </div>
                    </div>
                </div>
                <div className="xl:flex justify-end xl:pl-16 px-4 absolute bottom-5 w-full">
                    <button onClick={() => handleActiveMenu(3)} className="bg-cyan-400 mt-5 px-4 py-2 rounded-md text-white w-full xl:w-auto">
                        مرحله بعد
                    </button>
                </div>
            </div>  
            <div onClick={() => handleActiveMenu(3)} className={`mt-5 cursor-pointer bg-white p-6 rounded-tr-md rounded-tl-md flex justify-between items-center transition-all ${activeMenu === 3 ? 'shadow-md' : 'rounded-b-md delay-[600ms] shadow-sm'}`}>
                <span className="font-bold xl:text-xl text-base">
                    <span>اطلاعات تماس</span>
                    {errorContactInformation !== 0  ? <span className="bg-red-500 mr-2 rounded-full px-2 text-white font-medium text-base">{errorContactInformation}</span> : ''}
                </span>
                <span>
                    <i className={`fa-solid fa-chevron-down fa-xs transition-all duration-700 ${activeMenu === 3 ? 'rotate-180' : ''}`}></i>
                </span>
            </div>
            <div className={`relative bg-white shadow-md border-t transition-all overflow-hidden duration-700 rounded-b-md ${activeMenu === 3 ? `${errors.hasError ? 'h-[75rem] md:h-[45rem] xl:h-[31rem]' : 'h-[59rem] md:h-[37rem] xl:h-[26rem]'} pb-5 xl:pb-0` : 'h-0 border-t-0'}`}>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-x-4 grid-cols-1 justify-items-center xl:px-11 px-4 gap-y-6 pt-10 text-sm xl:text-base">
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>شماره همراه هنرجو</span>
                            </div>
                            <input type="number" onChange={(e) => handleFields(e,'phone')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.phone}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">شماره همراه پدر</div>
                            <input type="number" onChange={(e) => handleFields(e,'fatherPhone')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.fatherPhone}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">شماره همراه مادر</div>
                            <input type="number" onChange={(e) => handleFields(e,'motherPhone')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.motherPhone}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>شماره اطلاع رسانی اولیاء</span>
                            </div>
                            <input type="number" onChange={(e) => handleFields(e,'smsPhone')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.smsPhone}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">شماره تلفن منزل</div>
                            <input type="number" onChange={(e) => handleFields(e,'homePhone')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.homePhone}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">شماره تماس ضروری</div>
                            <input type="number" onChange={(e) => handleFields(e,'emergencyPhone')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.emergencyPhone}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>آدرس</span>
                            </div>
                            <input type="text" onChange={(e) => handleFields(e,'address')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.address}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">وضعیت محل سکونت</div>
                            <select onChange={(e) => handleFields(e,'homeState')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md ">
                                <option value="1">وضعیت محل سکونت خود را انتخاب کنید</option>
                                <option value="1">ملکی</option>
                                <option value="2">استیجاری</option>
                            </select>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.homeState}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">شماره پستی</div>
                            <input type="number" onChange={(e) => handleFields(e,'postalCode')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'contactInformation'} setErrorContactInformation={setErrorContactInformation} error={errors.postalCode}/>
                        </div>
                    </div>
                </div>
                <div className="xl:flex justify-end xl:pl-16 px-4 absolute bottom-5 w-full">
                    <button onClick={() => handleActiveMenu(4)} className="bg-cyan-400 mt-5 px-4 py-2 rounded-md text-white w-full xl:w-auto">
                        مرحله بعد
                    </button>
                </div>
            </div>
            <div onClick={() => handleActiveMenu(4)} className={`mt-5 cursor-pointer bg-white p-6 rounded-tr-md rounded-tl-md flex justify-between items-center transition-all ${activeMenu === 4 ? 'shadow-md' : 'rounded-b-md delay-[600ms] shadow-sm'}`}>
                <span className="font-bold xl:text-xl text-base">
                    <span>اطلاعات خانواده</span>
                    {errorFamilyInformation !== 0  ? <span className="bg-red-500 mr-2 rounded-full px-2 text-white font-medium text-base">{errorFamilyInformation}</span> : ''}
                </span>
                <span>
                    <i className={`fa-solid fa-chevron-down fa-xs transition-all duration-700 ${activeMenu === 4 ? 'rotate-180' : ''}`}></i>
                </span>
            </div>
            <div className={`relative bg-white shadow-md border-t transition-all overflow-hidden duration-700 rounded-b-md ${activeMenu === 4 ? `${errors.hasError ? 'h-[103rem] md:h-[81rem] xl:h-[55rem]' : 'h-[85rem] md:h-[67rem] xl:h-[44rem]'} pb-5 xl:pb-0` : 'h-0 border-t-0'}`}>
                <div className="grid xl:grid-cols-2 xl:grid-flow-row-dense xl:gap-x-4 gap-y-4 pt-5 px-4">
                    <div className="w-full xl:col-start-1">
                        <div className="flex justify-start items-center gap-x-2 pr-2">
                            <input type="checkbox" onClick={(e) => fatherDeadHandle(e)}/>
                            <div className="">پدر فوت شده</div>      
                        </div>
                    </div>
                    <div className="w-full col-start-1">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>تحصیلات پدر</span>
                            </div>
                            <select value={fields.fatherEducation} disabled={fields.fatherDead} onChange={(e) => handleFields(e,'fatherEducation')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md disabled:bg-gray-100">
                                <option value="">تحصیلات پدر خود را انتخاب کنید</option>
                                <option value="ابتدایی">ابتدایی</option>
                                <option value="سیکل">سیکل</option>
                                <option value="دیپلم">دیپلم</option>
                                <option value="فوق دیپلم">فوق دیپلم</option>
                                <option value="لیسانس">لیسانس</option>
                                <option value="فوق لیسانس">فوق لیسانس</option>
                                <option value="دکتوری">دکتوری</option>
                            </select>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.fatherEducation}/>
                        </div>
                    </div>
                    <div className="w-full col-start-1">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>شغل پدر</span>
                            </div>
                            <input value={fields.fatherJob} disabled={fields.fatherDead} onChange={(e) => handleFields(e,'fatherJob')} type="text" className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.fatherJob}/>
                        </div>
                    </div>
                    <div className="w-full col-start-1">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">آدرس محل کار</div>
                            <input value={fields.fatherJobAddress} disabled={fields.fatherDead} onChange={(e) => handleFields(e,'fatherJobAddress')} type="text" className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.fatherJobAddress}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex justify-start items-center gap-x-2 pr-2">
                            <input type="checkbox" onClick={(e) => motherDeadHandle(e)}/>
                            <div className="">مادر فوت شده</div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>تحصیلات مادر</span>
                            </div>
                            <select value={fields.motherEducation} disabled={fields.motherDead} onChange={(e) => handleFields(e,'motherEducation')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md disabled:bg-gray-100">
                                <option value="">تحصیلات مادر خود را انتخاب کنید</option>
                                <option value="ابتدایی">ابتدایی</option>
                                <option value="سیکل">سیکل</option>
                                <option value="دیپلم">دیپلم</option>
                                <option value="فوق دیپلم">فوق دیپلم</option>
                                <option value="لیسانس">لیسانس</option>
                                <option value="فوق لیسانس">فوق لیسانس</option>
                                <option value="دکتوری">دکتوری</option>
                            </select>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.motherEducation}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>شغل مادر</span>
                            </div>
                            <input value={fields.motherJob} disabled={fields.motherDead}  onChange={(e) => handleFields(e,'motherJob')} type="text" className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.motherJob}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">آدرس محل کار</div>
                            <input value={fields.motherJobAddress} disabled={fields.motherDead}  onChange={(e) => handleFields(e,'motherJobAddress')} type="text" className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.motherJobAddress}/>
                        </div>
                    </div>
                </div>
                <div className="grid lg:grid-cols-3 xl:grid-flow-row-dense md:grid-cols-2 gap-x-4 grid-cols-1 justify-items-center xl:px-11 px-4 gap-y-6 xl:pt-10 pt-5 text-sm xl:text-base">
                    <div className="w-full">
                        <div className="flex justify-start items-center mt-2 gap-x-2 xl:h-full pr-2">
                            <input type="checkbox" onClick={(e) => handleDivorced(e)}/>
                            <div className="">والدین طلاق گرفته اند</div>
                        </div>
                    </div>
                    <div className="w-full xl:col-start-1">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">زندگی با</div>
                            <select value={fields.lifeWith} onChange={(e) => handleFields(e,'lifeWith')} disabled={!fields.divorced} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md disabled:bg-gray-100">
                                <option value=""></option>
                                <option value="پدر">پدر</option>
                                <option value="مادر">مادر</option>
                                <option value="پدربزرگ مادربزرگ">پدربزرگ مادربزرگ</option>
                                <option value="سایر">سایر</option>
                            </select>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.lifeWith}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">تعداد برادر</div>
                            <input type="number" onChange={(e) => handleFields(e,'brothers')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.brothers}/>
                        </div>
                    </div>
                    <div className="w-full xl:col-start-2">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">تعداد خواهر</div>
                            <input type="number" onChange={(e) => handleFields(e,'sisters')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.sisters}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>تحت پوشش</span>
                            </div>
                            <select onChange={(e) => handleFields(e,'coveredBy')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md ">
                                <option value=""></option>
                                <option value="بنیاد شهید و امور ایثارگران">بنیاد شهید و امور ایثارگران</option>
                                <option value="کمیته امداد امام خمینی ره">کمیته امداد امام خمینی ره</option>
                                <option value="سازمان بهزیستی">سازمان بهزیستی</option>
                            </select>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.coveredBy}/>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="w-full mb-2">
                                <span>وضعیت ایثارگری</span>
                            </div>
                            <input type="text" onChange={(e) => handleFields(e,'sacrifice')} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            <ErorrForm group={'familyInformation'} setErrorFamilyInformation={setErrorFamilyInformation} error={errors.sacrifice}/>
                        </div>
                    </div>
                    <div className="w-full mt-3 xl:mt-0">
                        <div className="flex justify-start items-center gap-x-2 pr-2">
                            <input type="checkbox" onClick={(e) => handleFields(e,'service')}/>
                            <div className="">درخواست سرویس مدرسه</div>
                        </div>
                    </div>
                    <div className="w-full xl:col-start-1">
                        <div className="flex justify-start items-center gap-x-2 xl:h-5 pr-2">
                            <input type="checkbox" onClick={(e) => handleFields(e,'forum')}/>
                            <div className="">علاقه مند به عضویت در انجمن اولیا و مربیان</div>
                        </div>
                    </div>
                </div>
                <div className="xl:flex justify-end xl:pl-16 px-4 absolute bottom-5 w-full">
                    <button onClick={() => handleSubmitForm()} className="bg-cyan-400 hover:bg-sky-600 mt-5 px-4 py-2 rounded-md text-white w-full xl:w-auto">
                        ثبت نام
                    </button>
                </div>
            </div>  
        </div>
    )
}

export default CreateStudent