import { Link } from "react-router-dom"
import Memari from "../../images/memari_dakhely-310x165.jpg"
import Image1 from "../../images/0523-110x75.jpg"
import Image2 from "../../images/qni-110x75.jpg"
import { useState } from "react"

const Majors = () => {

    const [majorID,setMajorID] = useState(0)

    const MajorsData = [
        {id:1,content : 'رشته ی معماری داخلی یکی از رشته های هنرستان و زیر مجموعه گروه هنر در شاخه فنی و حرفه ای است و زمینه فعالیت گسترده ای دارد. طراحی و معماری داخلی نمایشگاه های بزرگ و کوچک، صحنه آرایی فضای برگزاری سمینارها و جشنواره ها، طراحی و آرایش داخلی فروشگاه های بزرگ و کوچک، هتل ها، ادارات و بانک ها، مجتمع های تجاری، خانه های مسکونی، سالن ها، پایانه ها و فرودگاه ها، احیاء و باز زنده سازی بناهای تاریخی و… همگی مواردی هستند که نیازمند طراح معماری داخلی می باشند.فارغ التحصیلان این رشته تحت عنوان طراح معماری داخلی زیر نظر مهندسین در شرکت های دولتی و غیر دولتی مشغول به کار می شوند'},
        {id:2,content : 'رشته ی معماری داخلی یکی از رشته های هنرستان و زیر مجموعه گروه هنر در شاخه فنی و حرفه ای است و زمینه فعالیت گسترده ای دارد. طراحی و معماری داخلی نمایشگاه های بزرگ و کوچک، صحنه آرایی فضای برگزاری سمینارها و جشنواره ها، طراحی و آرایش داخلی فروشگاه های بزرگ و کوچک، هتل ها، ادارات و بانک ها، مجتمع های تجاری، خانه های مسکونی، سالن ها، پایانه ها و فرودگاه ها، احیاء و باز زنده سازی بناهای تاریخی و… همگی مواردی هستند که نیازمند طراح معماری داخلی می باشند.فارغ التحصیلان این رشته تحت عنوان طراح معماری داخلی زیر نظر مهندسین در شرکت های دولتی و غیر دولتی مشغول به کار می شوند'},
        {id:3,content : 'رشته ی معماری داخلی یکی از رشته های هنرستان و زیر مجموعه گروه هنر در شاخه فنی و حرفه ای است و زمینه فعالیت گسترده ای دارد. طراحی و معماری داخلی نمایشگاه های بزرگ و کوچک، صحنه آرایی فضای برگزاری سمینارها و جشنواره ها، طراحی و آرایش داخلی فروشگاه های بزرگ و کوچک، هتل ها، ادارات و بانک ها، مجتمع های تجاری، خانه های مسکونی، سالن ها، پایانه ها و فرودگاه ها، احیاء و باز زنده سازی بناهای تاریخی و… همگی مواردی هستند که نیازمند طراح معماری داخلی می باشند.فارغ التحصیلان این رشته تحت عنوان طراح معماری داخلی زیر نظر مهندسین در شرکت های دولتی و غیر دولتی مشغول به کار می شوند'},
        {id:4,content : 'رشته ی معماری داخلی یکی از رشته های هنرستان و زیر مجموعه گروه هنر در شاخه فنی و حرفه ای است و زمینه فعالیت گسترده ای دارد. طراحی و معماری داخلی نمایشگاه های بزرگ و کوچک، صحنه آرایی فضای برگزاری سمینارها و جشنواره ها، طراحی و آرایش داخلی فروشگاه های بزرگ و کوچک، هتل ها، ادارات و بانک ها، مجتمع های تجاری، خانه های مسکونی، سالن ها، پایانه ها و فرودگاه ها، احیاء و باز زنده سازی بناهای تاریخی و… همگی مواردی هستند که نیازمند طراح معماری داخلی می باشند.فارغ التحصیلان این رشته تحت عنوان طراح معماری داخلی زیر نظر مهندسین در شرکت های دولتی و غیر دولتی مشغول به کار می شوند'},
    ]

    const handleMajor = (majorID) => {
        setMajorID((oldMajor) => oldMajor !== majorID ? majorID : 0)
    }

    return (
            <div className="w-full lg:flex lg:justify-center">
                <div className="flex flex-col gap-y-2 w-full lg:w-4/12 py-5 px-2 mb-14">
                    {MajorsData.map(major => (
                        <div onClick={() => handleMajor(major.id)} className="bg-white rounded-lg overflow-hidden shadow-md cursor-default" key={major.id}>
                            <div className={`flex ${majorID === major.id ? 'flex-col' : ''} items-center py-2`}>
                                <div className="px-2 py-2">
                                    <img src={Memari} className={`object-contain rounded-lg ${majorID === major.id ? 'w-full' : 'w-56'}`} alt="" />
                                </div>
                                <div className="p-2 flex flex-col gap-y-1 w-10/12">
                                    <div className="text-sm">معماری</div>
                                    <div className="text-xs text-gray-600">{majorID === major.id ? major.content : MajorsData[0].content.slice(0,50) + '...'}</div>
                                </div>
                            </div>
                            <div className="flex justify-center border-t border-gray-200 bg-[#fcfcfc]">
                                <button className="cursor-default">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 transition-all duration-700 ${majorID === major.id ? 'rotate-180' : 'rotate-0'}`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ))} 
                </div>
            </div>
    )
}

export default Majors