import { Route, Routes, useNavigate } from "react-router"
import Home from "../pages/Student/Home"
import Header from "../components/Student/Header"
import MobileMenu from "../components/Student/MobileMenu"
import News from "../pages/Student/News"
import Chats from "../pages/Student/Chats"
import Chat from "../pages/Student/Chat"
import SelectUnit from "../pages/Student/SelectUnit"
import DisciplinaryCases from "../pages/Student/DisciplinaryCases"
import Protests from "../pages/Student/Protests"
import { useContext, useEffect, useState } from "react"
import Menu from "../components/Student/Menu"
import Padding from "../components/Padding"
import { LoginContext } from "../components/Context/LoginContext"
import DetailNews from "../pages/Student/DetailNews"
import axios from "axios"

const Student = () => {
    const navigator = useNavigate()

    const [isOpenMenu,setIsOpenMenu] = useState(false)
    const {isLogin} = useContext(LoginContext)
    const [verify,setVerify] = useState(null)

    useEffect(() => {
        if(!isLogin)
            navigator('/login')
        if(verify===null)
            axios.get(`${process.env.REACT_APP_AddressIP}/api/student/verify`,{
                headers : {
                    Authorization : `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(data => setVerify(data.data.verify))
    })
    return (
        <>
            <Menu verify={verify} isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu}/>
            <Padding className={`${isOpenMenu ? 'blur-xs' : ''} fixed bottom-0 w-full h-full lg:overflow-y-auto overflow-y-scroll`}>
                <Header isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/news" element={<News />}/>
                    <Route path="/detailnews/:id" element={<DetailNews />}/>
                    <Route path="/chats" element={<Chats />}/>
                    <Route path="/selectunit" element={<SelectUnit />}/>
                    <Route path="/disciplinarycases" element={<DisciplinaryCases />}/>
                    <Route path="/protests" element={<Protests />}/>
                    <Route path="/chat/:id" element={<Chat />}/>
                </Routes>
                <MobileMenu />
            </Padding>
        </>
    )
}

export default Student