import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { AdminContext } from "../../../components/Context/AdminContext"
import Toast from "../../../components/Toast"


const CreateSelectUnit = () => {
    const [selectLesson,setSelectLesson] = useState(null)
    const [chapter,setChapter] = useState([])
    const [lessons,setLessons] = useState()
    const [students,setStudents] = useState()
    const [studentId,setStudentId] = useState(null)
    const navigate = useNavigate()
    const {adminPath} = useContext(AdminContext)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/get-lessons`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => setLessons(data.data.lessons))
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/management-users`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => setStudents(data.data.students))
    },[])
    const handleSelectLesson = (e) => {
        setChapter([])
        setSelectLesson(JSON.parse(e.target.value))
    }
    const handlePodeman = (podeman) => {
        const existsPodman = chapter.find((podman) => podman === podeman) ? true : false
        if(existsPodman)
        setChapter(chapter.filter(podman => podeman !== podman));
        else
        setChapter((oldchapter) => [...oldchapter,podeman])
    }
    const handleSelectUnit = () => {
        if(selectLesson && (!selectLesson.type || chapter.length))
        {
            axios.post(`${process.env.REACT_APP_AddressIP}/api/admin/select-unit`,{
                course_id : selectLesson.id,
                student_id : studentId,
                chapter : chapter.length ? chapter.sort().join('-') : 0,
            },{
                headers : {
                    Authorization : `Bearer ${localStorage.getItem('token')}`
                }
            }).then(data => {
                    Toast('success',data)
                    navigate(`/${adminPath}/selectunit`)
            }).catch(e => {
                if(e.request.status === 403)
                {
                    Toast('error',e)
                }
            })
        }
    }
    return (
        <div className="p-4 flex justify-center">
            <div className="bg-white shadow rounded-xl w-full flex flex-col p-4 lg:w-5/12">
                <select onChange={(e) => handleSelectLesson(e)} className="bg-white border-gray-300 border mb-5 outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 focus:ring-indigo-500 rounded-md shadow-sm mt-1 block w-full">
                    <option selected disabled>انتخاب درس</option>
                    {lessons && lessons.map(lesson => (
                        <option value={JSON.stringify({...lesson})}>{lesson.lesson_name}</option>
                        ))}
                </select>
                {selectLesson && selectLesson.is_chapter ? 
                    <div className="border mb-5 border-gray-300 overflow-hidden shadow-sm flex justify-evenly rounded-md text-gray-500 text-sm ">
                        <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter.find((podmon) => podmon === 1) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(1)}>1</button>
                        <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter.find((podmon) => podmon === 2) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(2)}>2</button>
                        <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter.find((podmon) => podmon === 3) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(3)}>3</button>
                        <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter.find((podmon) => podmon === 4) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(4)}>4</button>
                        <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5  ${chapter.find((podmon) => podmon === 5) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(5)}>5</button>
                    </div>
                    : ''
                }
                <select onChange={(e) => setStudentId(e.target.value)} className="bg-white border-gray-300 border mb-5 outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 focus:ring-indigo-500 rounded-md shadow-sm mt-1 block w-full">
                    <option selected disabled>انتخاب دانش آموز</option>
                    {students && students.map(student => (
                        <option value={student.id}>{student.first_name+' '+student.last_name+' '+student.nation_code}</option>
                        ))}
                </select>
                <div className="flex justify-center">
                    <button onClick={handleSelectUnit} className="cursor-default lg:cursor-pointer bg-sky-400 px-4 py-2 rounded-md text-white shadow hover:bg-sky-600">انتخاب درس</button>
                </div>
            </div>
        </div>
    )
}

export default CreateSelectUnit