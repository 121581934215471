import axios from "axios"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import Loading from "../../components/Student/Loading"
import Toast from "../../components/Toast"

const SelectUnit = () => {
    const [selectUnits,setSelectUnits] = useState([])

    const [loading,setLoading] = useState(true)
 
    const [selectLesson,setSelectLesson] = useState(null)

    const [chapter,setChapter] = useState([])

    const [lessons,setLessons] = useState()

    const [closedForm,setClosedForm] = useState(true)

    const [closedMessage,setClosedMessage] = useState(null)

    const [disableDelete,setDisableDelete] = useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/student/get-lessons`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => setLessons(data.data.units))
        axios.get(`${process.env.REACT_APP_AddressIP}/api/student/select-unit`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.data.code === 200)
            {
                setClosedForm(false)
                const units = data.data.units_selected
                const newUnits = units.map(unit => unit.chapter != 0 ? ({...unit,chapter : [...unit.chapter.split('-')]}) : ({...unit,chapter :0}))
                setSelectUnits([...newUnits])
                setLoading(false)
            }
            if(data.data.code === 403){
                const units = data.data.units_selected
                const newUnits = units.map(unit => unit.chapter != 0 ? ({...unit,chapter : [...unit.chapter.split('-')]}) : ({...unit,chapter :0}))
                setSelectUnits([...newUnits])
                setClosedForm(true)
                setLoading(false)
                setDisableDelete(true)
                setClosedMessage(data.data.message)
            }
        })
        .catch((e) => {
            // if(e.request.status===0){
            //     Toast('error',e)
            // }
        })
    },[])

    const handlePodeman = (podeman) => {
        const existsPodman = chapter.find((podman) => podman === podeman) ? true : false
        if(existsPodman)
        setChapter(chapter.filter(podman => podeman !== podman));
        else
        setChapter((oldchapter) => [...oldchapter,podeman])
    }

    const handleSelectUnit = () => {
        setLoading(true)
        if(selectLesson && (!selectLesson.type || chapter.length))
        {
            axios.post(`${process.env.REACT_APP_AddressIP}/api/student/select-unit`,{
                course_id : selectLesson.id,
                chapter : chapter.length ? chapter.sort().join('-') : 0,
            },{
                headers : {
                    Authorization : `Bearer ${localStorage.getItem('token')}`
                }
            }).then(data => {
                setSelectUnits((oldUnits) => [...oldUnits,{...selectLesson,id:data.data.id,course_id:selectLesson.id,chapter : chapter}])
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#16a34a',
                    position: 'top-end',
                    color : 'white',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                  
                  Toast.fire({
                    title: data.data.message
                  })
                  setLoading(false)
            }).catch(e => {
                if(e.response.data.code === 403)
                {
                    const Toast = Swal.mixin({
                        toast: true,
                        background : '#dc2626',
                        position: 'top-end',
                        color : 'white',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                      
                      Toast.fire({
                        title: e.response.data.message
                      })
                      setLoading(false)
                }
            })
        }
    }

    const handleRemoveUnit = (unit) => {
        setLoading(true)
        axios.delete(`${process.env.REACT_APP_AddressIP}/api/student/select-unit/${unit.id}`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            setSelectUnits(selectUnits.filter(oldUnit => oldUnit.id !== unit.id));
            const Toast = Swal.mixin({
                toast: true,
                background : '#16a34a',
                position: 'top-end',
                color : 'white',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
              
              Toast.fire({
                title: data.data.message
              })
              setLoading(false)
        })
    }

    const handleSelectLesson = (e) => {
        setChapter([])
        setSelectLesson(JSON.parse(e.target.value))
    }

    return (
        <>
            <div className=" z-30 sticky top-14 w-full border-t shadow-sm right-0">
                <div className="bg-white flex justify-center items-center p-1 gap-x-1 px-4 my-0 w-full ">
                    <div className="flex items-center gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                        </svg>
                    </div>
                    <span>انتخاب واحد</span>
                </div>
            </div>  
                <div className="flex flex-col gap-y-4 items-center px-5 pt-28 pb-20">
                {!loading && closedForm &&
                    <div className="bg-amber-100 text-sm shadow rounded-xl w-full text-center p-4">
                        {closedMessage}
                    </div>
                }
                    {!closedForm &&
                        <div className="bg-white shadow rounded-xl w-full flex flex-col p-4">
                            <select onChange={(e) => handleSelectLesson(e)} className="bg-white border-gray-300 border mb-5 outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 focus:ring-indigo-500 rounded-md shadow-sm mt-1 block w-full">
                                <option selected disabled>انتخاب درس</option>
                                {lessons && lessons.map(lesson => (
                                    <option value={JSON.stringify({...lesson})}>{lesson.lesson_name}</option>
                                    ))}
                            </select>
                            {/* {console.log(selectLesson && selectLesson.type)} */}
                            {selectLesson && selectLesson.is_chapter ? 
                                <div className="border mb-5 border-gray-300 overflow-hidden shadow-sm flex justify-evenly rounded-md text-gray-500 text-sm ">
                                    <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter.find((podmon) => podmon === 1) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(1)}>1</button>
                                    <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter.find((podmon) => podmon === 2) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(2)}>2</button>
                                    <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter.find((podmon) => podmon === 3) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(3)}>3</button>
                                    <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter.find((podmon) => podmon === 4) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(4)}>4</button>
                                    <button disabled={selectLesson && !selectLesson.is_chapter} className={`text-center cursor-default w-3/12 py-1.5  ${chapter.find((podmon) => podmon === 5) ? 'bg-sky-400 text-white' : ''}`} onClick={() => handlePodeman(5)}>5</button>
                                </div>
                                : ''
                            }
                            <div className="flex justify-center">
                                <button onClick={handleSelectUnit} className="cursor-default bg-sky-400 px-4 py-2 rounded-md text-white shadow hover:bg-sky-600">انتخاب درس</button>
                            </div>
                        </div>
                    }
                    {loading && <Loading />}
                    {!loading &&
                        <div className="flex flex-col gap-y-4 w-full">
                        {selectUnits && selectUnits.map(unit => (
                            <div className="bg-white shadow-md w-full overflow-hidden rounded-xl">
                                <div className="flex justify-between items-center border-b border-gray-300 py-1 bg-sky-200 px-2">
                                    <div className="flex gap-x-1.5 items-center">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                            </svg>
                                        </span>
                                        <span className="text-xs">{unit.course_id}</span>
                                    </div>
                                <div className="flex gap-x-3 items-center justify-center">
                                    {unit.chapter.length ?
                                        
                                    <div className="border border-gray-300 overflow-hidden shadow-sm flex items-center justify-evenly rounded-full text-gray-500 text-xs">
                                        <button className={`text-center cursor-default w-5 h-5 border-l ${unit.chapter.find((chapter) => chapter == 1) ? 'bg-sky-400 text-white' : 'bg-sky-50'}`} >1</button>
                                        <button className={`text-center cursor-default w-5 h-5 border-l ${unit.chapter.find((chapter) => chapter == 2) ? 'bg-sky-400 text-white' : 'bg-sky-50'}`} >2</button>
                                        <button className={`text-center cursor-default w-5 h-5 border-l ${unit.chapter.find((chapter) => chapter == 3) ? 'bg-sky-400 text-white' : 'bg-sky-50'}`} >3</button>
                                        <button className={`text-center cursor-default w-5 h-5 border-l ${unit.chapter.find((chapter) => chapter == 4) ? 'bg-sky-400 text-white' : 'bg-sky-50'}`} >4</button>
                                        <button className={`text-center cursor-default w-5 h-5  ${unit.chapter.find((chapter) => chapter == 5) ? 'bg-sky-400 text-white' : 'bg-sky-50'}`} >5</button>
                                    </div>
                                    : ''
                                }
                                {!disableDelete &&
                                    <div onClick={() => handleRemoveUnit(unit)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </div>
                                }
                                </div>
                                
                                </div>
                                <div className="flex justify-between items-center overflow-hidden">
                                    <div className="font-bold text-sm p-2 border-b truncate w-full">
                                        {unit.lesson_name}
                                    </div>
                                    <div className="text-xs text-gray-400 pl-2">
                                        یازدهم
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    }
                </div>
        </>
    )
}

export default SelectUnit