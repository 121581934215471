const Graduates = () => {

    const graduatesData = [
        {id : 1,image : '/images/qni-110x75.jpg',name : 'علی مرادی'},
        {id : 2,image : '/images/qni-110x75.jpg',name : 'علی مرادی'},
        {id : 3,image : '/images/qni-110x75.jpg',name : 'علی مرادی'},
    ]

    return (
        <div>
            <div className="grid grid-cols-2 p-2 gap-4">
                {graduatesData.map(graduate => (
                    <div key={graduate.id} className="bg-white shadow-lg rounded-xl p-4 flex flex-col items-center gap-y-2">
                        <img src={graduate.image} className="rounded-full w-28 h-28 object-cover" alt="" />
                        <div className="text-sm">
                            {graduate.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Graduates