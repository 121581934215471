import { Link } from "react-router-dom"

const Chats = () => {

    const chats = [
        {id:1,title : 'کانال اطلاع رسانی هنرستان',user : {}},
        {id:2,title : 'مدیریت هنرستان',user : {name : 'حسین یعقوبی'}},
        {id:3,title : 'معاونت آموزشی',user : {name : 'عبدالعظیم وظیفه شناس'}}

    ]

    return (
        <>
            <div className=" z-30 sticky top-14 w-full border-t shadow-sm right-0">
                <div className="bg-white flex justify-center items-center p-1 gap-x-1 px-4 my-0 w-full ">
                    <div className="flex items-center gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                        </svg>
                    </div>
                    <span>پیام ها</span>
                </div>
            </div>  
            <div className="flex flex-col gap-y-4 p-4 pt-28">
                {chats.map(chat => (
                    <Link to={`/dashboard/chat/${chat.id}`} className="bg-white flex gap-x-2 items-center p-2 rounded-2xl border border-gray-300 shadow-md relative">
                        <span className="bg-blue-600 absolute -top-2 -left-1 pt-0.5 px-2 rounded-full text-white text-sm">1</span>
                        <img src="/images/qni-110x75.jpg" className="w-16 h-16 rounded-full border border-gray-300" alt="" />
                        <div>
                            <div className="text-sm">{chat.title}</div>
                            <div className="text-sm text-gray-500">{chat.user && chat.user.name}</div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    )
}

export default Chats