import { useEffect, useRef, useState } from "react"
import axios from "axios"
import ReactToPrint from "react-to-print"
import Toast from "../../../components/Toast"


const IndexSelectUnit = () => {

    const [units,setUnits] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/select-unit`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.request.status === 200){
                const newUnits = data.data.units.map(unit => unit.chapter != 0 ? ({...unit,chapter : [...unit.chapter.split('-')]}) : ({...unit,chapter :0}))
                setUnits([...newUnits])
            }
        })
        .catch(e => {
            if(e.request.status===0){
            Toast('error',e)
            }else if(e.request.status === 404){
                setUnits(e.response.data.message)
            }
              
            
        })
    },[])

    const list = useRef()

    return(
        <>
            <div ref={list} dir="rtl" className="flex flex-col print:items-start items-center gap-y-4 px-4 pt-5 print:pt-0 pb-20">
            <div className="bg-white px-4 py-2 text-lg font-bold rounded-lg print:w-full print:text-center">
                لیست انتخاب واحد
            </div>
            <ReactToPrint
                trigger={() => <button className="lg:flex gap-x-1 hidden hover:bg-gray-300 bg-white px-4 py-2 rounded-lg print:hidden fixed bottom-10 right-20">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                        </svg>
                    </span>
                </button>}
                content={() => list.current}
            />
            {!Array.isArray(units) && 
                <div className="bg-white p-2 rounded-md shadow text-center w-full lg:w-4/12">
                    {units}
                </div>
            }
            {Array.isArray(units) && units.map(unit => (
                
                <div key={unit.id} className="w-full print:break-inside-avoid item-list print:even:bg-gray-200 lg:max-w-5xl rounded-md shadow flex lg:flex-row flex-col gap-y-4  items-center print:items-start justify-between bg-white p-4">
                        {unit.student}
                        <div className="flex lg:flex-row flex-col w-full gap-y-1 lg:justify-between print:justify-between print:w-full lg:w-full print:flex-row">
                            <div className="flex flex-col print:flex-row lg:flex-row justify-between lg:justify-start print:flex">
                                <div className="flex w-full lg:w-auto print:w-auto justify-between">
                                    <div className=" py-0.5 px-4 flex items-center gap-x-2">
                                        <span className="text-sm whitespace-nowrap">{unit.student && unit.student.first_name+' '+unit.student.last_name}</span>
                                    </div>
                                    <div className=" py-0.5 px-4 lg:flex print:flex items-center gap-x-2">
                                        <span className="text-sm whitespace-nowrap">{unit.student && unit.student.nation_code}</span>
                                    </div>
                                </div>
                                <div className=" py-0.5 px-4 flex print:flex items-center gap-x-2 print:break-words print:w-64">
                                    <span className="text-sm">{unit.course.lesson_name}</span>
                                </div>
                            </div>
                            {unit.chapter != 0? 
                                <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                    <span className="text-xs whitespace-nowrap text-gray-600 lg:hidden print:hidden">پودمان</span>
                                    <div className="border-t-2 border-dotted border-gray-300 w-6/12 lg:hidden print:hidden"></div>
                                    
                                    <div className="border border-gray-300 overflow-hidden shadow-sm flex items-center justify-start rounded-lg text-gray-500 text-xs lg:text-sm w-48 lg:w-auto print:w-auto">
                                        <button className={`text-center cursor-default w-full h-5 lg:w-8 lg:h-full lg:py-1 print:w-8 print:h-full print:py-1 border-l ${unit.chapter.find((chapter) => chapter == 1) ? 'bg-sky-400 print:bg-gray-600 text-white' : 'bg-sky-50 print:bg-white'}`} >1</button>
                                        <button className={`text-center cursor-default w-full h-5 lg:w-8 lg:h-full lg:py-1 print:w-8 print:h-full print:py-1 border-l ${unit.chapter.find((chapter) => chapter == 2) ? 'bg-sky-400 print:bg-gray-600 text-white' : 'bg-sky-50 print:bg-white'}`} >2</button>
                                        <button className={`text-center cursor-default w-full h-5 lg:w-8 lg:h-full lg:py-1 print:w-8 print:h-full print:py-1 border-l ${unit.chapter.find((chapter) => chapter == 3) ? 'bg-sky-400 print:bg-gray-600 text-white' : 'bg-sky-50 print:bg-white'}`} >3</button>
                                        <button className={`text-center cursor-default w-full h-5 lg:w-8 lg:h-full lg:py-1 print:w-8 print:h-full print:py-1 border-l ${unit.chapter.find((chapter) => chapter == 4) ? 'bg-sky-400 print:bg-gray-600 text-white' : 'bg-sky-50 print:bg-white'}`} >4</button>
                                        <button className={`text-center cursor-default w-full h-5 lg:w-8 lg:h-full lg:py-1 print:w-8 print:h-full print:py-1  ${unit.chapter.find((chapter) => chapter == 5) ? 'bg-sky-400 print:bg-gray-600 text-white' : 'bg-sky-50 print:bg-white'}`} >5</button>
                                    </div>
                                </div>
                            : ''
                            }
                        </div>
                        
                    </div>
            ))}
        </div>
        </>
    )
}

export default IndexSelectUnit