import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AdminContext } from "../../../components/Context/AdminContext"
import Swal from "sweetalert2"
import axios from "axios"
import Toast from "../../../components/Toast"


const IndexDiscipline = () => {

    const [disciplines,setDisciplines] = useState([])
    const {adminPath} = useContext(AdminContext)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/disciplines`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.request.status === 200){
                setDisciplines(data.data.disciplines)
            }
        })
        .catch(e => {
            if(e.request.status===0){
            Toast('error',e)
            }else if(e.request.status === 404){
                setDisciplines(e.response.data.message)
            }
              
            
        })
    },[])

    const deleteDiscipline = (id) => {
        axios.delete(`${process.env.REACT_APP_AddressIP}/api/admin/disciplines/${id}`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            setDisciplines((old) => old.filter(staff => staff.id !== id))
            Toast('success',data)
        }).catch(e => {
                if(e.request.status === 0)
                {
                    Toast('error',e)
                }
        })
    }
 
    const handleDisType = (disType) => {
        switch(disType){
            case 0:
            return 'تشویقی';
            case 1:
            return 'مورد انظباطی'
            case 2:
            return 'غیبت'
            default:
                
        }
    }

    return(
        <div className="flex flex-col items-center gap-y-4 px-4 pt-5 pb-20 ">
            {!Array.isArray(disciplines) && 
                <div className="bg-white p-2 rounded-md shadow text-center w-full lg:w-4/12">
                    {disciplines}
                </div>
            }
            {Array.isArray(disciplines) && disciplines.map(discipline => (
                <div key={discipline.id} className="w-full lg:max-w-5xl rounded-md shadow flex lg:flex-row flex-col gap-y-4  items-center justify-between bg-white p-4">
                        <div className="flex lg:flex-row flex-col gap-y-1 w-full">
                        
                            <div className=" py-0.5 px-4 flex items-center gap-x-2">
                                <span className="text-sm whitespace-nowrap">{discipline.student.first_name+' '+discipline.student.last_name}</span>
                            </div>
                            <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                <span className="text-sm whitespace-nowrap">{discipline.student.nation_code}</span>
                            </div>
                            <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                <span className="text-sm whitespace-nowrap">{discipline.title}</span>
                            </div>
                            <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                <span className="text-sm whitespace-nowrap">{discipline.description}</span>
                            </div>
                            <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                <span className="text-sm whitespace-nowrap">{handleDisType(discipline.dis_type)}</span>
                            </div>
                            <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                <span className="text-sm whitespace-nowrap">{discipline.score}</span>
                            </div>
                            <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                <span className="text-sm whitespace-nowrap">{discipline.decision ? 'کسر نمره' : 'نمره تشویقی'}</span>
                            </div>
                        </div>
                        <div className="flex gap-x-2 w-full lg:w-auto">
                            <Link to={`/${adminPath}/discipline/${discipline.id}/edit`} className="lg:cursor-pointer bg-purple-600 hover:bg-purple-700 cursor-default lg:p-2 text-white rounded-md w-full p-2 flex items-center justify-center text-xs">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                </svg>
                                <span className="lg:hidden">
                                    ویرایش
                                </span>
                            </Link>
                            <button onClick={() => deleteDiscipline(discipline.id)} className="lg:cursor-pointer cursor-default bg-red-500 text-white lg:p-2 rounded-md w-full p-2 flex items-center justify-center text-xs">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                                <span className="lg:hidden">
                                    حذف
                                </span>
                            </button>
                        </div>
                    </div>
            ))}
        </div>
    )
}

export default IndexDiscipline