import { Link } from "react-router-dom";

const Table = ({ records, fields, uniqueKey, editPath, deleteRecord }) => {
  return (
        <div className="bg-white rounded-xl w-full">
            <div></div>
            <div>
                {records.map(record => (
                    <div className="p-4">
                    <div className="flex justify-between mb-2">
                        <div>{}</div>
                        <div>2283845009</div>
                        <div className="flex justify-end">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                            </svg>
                        </div>
                        <div className="text-xs">دهم</div>
                        <div>نرم افزار</div>
                        <div></div>
                    </div>
                </div>
                ))}
            </div>
        </div>
  );
};

export default Table;
