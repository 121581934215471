import { Link } from "react-router-dom"
import Paginate from "../../components/Paginate"
import { useEffect, useState } from "react"
import axios from "axios"
import Swal from "sweetalert2"

const News = () => {

    const [news,setNews] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/all-news`)
        .then(data => setNews(data.data.news))
        .catch(e => {
            if(e.request.status===0)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#dc2626',
                    position: 'top-end',
                    iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7"><path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" /></svg>',
                    color : 'white',
                    showConfirmButton: false,
                    timer: 3000,
                    customClass:{
                        icon:'border-0'
                    },
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                
                Toast.fire({
                    title: 'خطا در برقراری ارتباط با سرور'
                })
            }
        })
    },[])

    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 gap-y-4 py-5 px-2">
                {news && news.map(news => (
                    <Link key={news.id} to={`/detailnews/${news.id}`} className="cursor-default lg:cursor-pointer bg-white rounded-lg shadow-md border border-gray-300">
                        <div className="flex items-center">
                            <div className="w-5/12">
                                <img src={news.pic} className="w-44 h-28 object-cover rounded-r-lg border-l border-gray-300" alt="" />
                            </div>
                            <div className="p-2 flex flex-col gap-y-1 w-9/12">
                                <div className="text-sm text-blue-950">{news.title}</div>
                                <div className="text-xs text-gray-600 break-words w-44">{news.content.slice(0,100)}...</div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="flex justify-center w-full pb-5 mb-16 lg:mb-0">
                <Paginate />
            </div>
        </>
    )
}

export default News