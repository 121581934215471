import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import Toast from "../../../components/Toast"
import { AdminContext } from "../../../components/Context/AdminContext"

const EditRole = () => {

    const {id} = useParams()
    const {adminPath} = useContext(AdminContext)
    const navigate = useNavigate()
    const [permissions,setPermissions] = useState([])
    const [name,setName] = useState(null)
    const [permissionList,setPermissionList] = useState([])
    const [allPermissions,setAllPermissions] = useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/get-permissions
        `,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => setPermissions(data.data.permissions))

        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/role/${id}
        `,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.request.status === 200)
            {
                setName(data.data.role.name)
                setPermissionList(data.data.permissions)
                checkAllPermission()
            }
        })
    },[])

    const checkAllPermission = () => {
        if(permissionList.length === permissions.length)
            setAllPermissions(true)
    }

    const handleSubmit = () => {
        axios.patch(`${process.env.REACT_APP_AddressIP}/api/admin/role/${id}`,{
            name,
            role_id: id,
            permission_list : permissionList
        },{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            Toast('success',data)
            navigate(`/${adminPath}/role/create`)
        })
    }

    const handlePermission = (id=null) => {
        if(permissionList.includes(id)){
            if(allPermissions)
            {
                setAllPermissions(false)
            }
            setPermissionList((old) => old.filter(listid => listid !== id))
        }else{
            setPermissionList((old) => [...old,id])
        }
        if(id===null){
            if(!allPermissions){
                setPermissionList([])
                permissions.map(permission => setPermissionList((old) => [...old,permission.id]))
                setAllPermissions(true)
            }else{
                setPermissionList([])
                setAllPermissions(false)
            }
        }
    }

    return (
        <div className="p-4 flex flex-col items-center gap-y-4 pb-16">
        <div className="bg-white shadow rounded-xl w-full flex flex-col gap-y-4 p-4 lg:w-5/12">
                    <div>
                        <input defaultValue={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="عنوان" className={`border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                    </div>
                    <div className="flex flex-col gap-y-4">
                        <div className="flex justify-between items-center gap-x-2 p-1 rounded-lg">
                            <span>انتخاب همه</span>
                            <button type="button" onClick={() => handlePermission()} class={`${allPermissions ? 'bg-blue-500' : 'bg-gray-200'} cursor-default flex w-8 flex-none lg:cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" role="switch" aria-checked="false" aria-labelledby="switch-1-label`}>
                                <span aria-hidden="true" class={`${allPermissions ? '-translate-x-3.5' : 'translate-x-0'} h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out`}></span>
                            </button>
                        </div>
                        {permissions && permissions.map(permission => (
                        <div className="flex justify-between items-center gap-x-2 p-1 rounded-lg">
                            <span>{permission.name}</span>
                            <button type="button" onClick={() => handlePermission(permission.id)} class={`${permissionList.includes(permission.id) ? 'bg-blue-500' : 'bg-gray-200'} cursor-default flex w-8 flex-none lg:cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" role="switch" aria-checked="false" aria-labelledby="switch-1-label`}>
                                <span aria-hidden="true" class={`${permissionList.includes(permission.id) ? '-translate-x-3.5' : 'translate-x-0'} h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out`}></span>
                            </button>
                        </div>
                        ))}
                    </div>
                    <div className="flex justify-center">
                        <button onClick={() => handleSubmit()} className="cursor-default lg:cursor-pointer bg-sky-400 text-white rounded-md shadow hover:bg-sky-600 px-4 py-2">بروزرسانی</button>
                    </div>
                    
                </div>
                </div>
    )
}

export default EditRole