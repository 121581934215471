import { useEffect, useState } from "react"
import Image2 from "../../images/qni-110x75.jpg"
import { useParams } from "react-router"
import axios from "axios"

const DetailNews = () => {



    const [news,setNews] = useState(null)

    const {id} = useParams()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/single-news?id=${id}`).then(data => {
            setNews(data.data.news)
        })
    },[])

    return (
        <div className="flex flex-col justify-center items-center ">
            <div className="bg-white rounded-lg mt-20 w-11/12 mb-28 overflow-hidden border border-gray-300">
                <div>
                    <div className="">
                        <img src={news && news.pic} className="w-full rounded-t-lg" alt="" />
                    </div>
                    <div className="px-4 font-bold text-xl py-3 pb-2">
                        {news && news.title}
                    </div>
                    <div className="mb-5 px-4">
                        <div className="text-sm text-justify">
                            {news && news.content}
                        </div>
                    </div>
                    <div className="flex justify-end pb-2 pl-4 text-gray-500 text-sm items-center">
                        <div>
                            <span>{news && news.date}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailNews