const Setting = () => {
    const handleSubmit = () => {
        
    }

    return(
        <div className="p-4 flex justify-center">
            <div className="bg-white shadow rounded-xl w-full flex flex-col gap-y-8 lg:gap-y-10 p-4 lg:w-5/12">
                <div className="flex flex-col w-full gap-y-2">
                    <div className="text-lg font-bold text-center lg:text-start">اعتراضات</div>
                    <div className="flex flex-col gap-y-2 lg:flex-row lg:gap-x-2">
                        <div className="w-full">
                            <div className="">تاریخ شروع</div>
                            <input type="number" placeholder="تاریخ شروع" onChange={(e) => null} className={`border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                        </div>   
                        <div className="w-full">
                            <div className="">تاریخ پایان</div>
                            <input type="number"placeholder="تاریخ پایان" onChange={(e) => null} className={`border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                        </div>    
                    </div>
                </div>  
                <div className="flex flex-col w-full gap-y-2">
                    <div className="text-lg font-bold text-center lg:text-start">انتخاب واحد</div>
                    <div className="flex flex-col gap-y-2 lg:flex-row lg:gap-x-2">
                        <div className="w-full">
                            <div className="">تاریخ شروع</div>
                            <input type="number" placeholder="تاریخ شروع" onChange={(e) => null} className={`border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                        </div>   
                        <div className="w-full">
                            <div className="">تاریخ پایان</div>
                            <input type="number"placeholder="تاریخ پایان" onChange={(e) => null} className={`border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                        </div>    
                    </div>
                </div>  
                <div className="flex lg:justify-end">
                    <button onClick={handleSubmit} className="cursor-default lg:cursor-pointer bg-sky-400 text-white rounded-md shadow hover:bg-sky-600 px-4 py-2 w-full lg:w-auto">بروزرسانی</button>
                </div>
            </div>
        </div>
    )
}

export default Setting