import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import Swal from "sweetalert2"

const Home = () => {


    const [news,setNews] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/all-news`)
        .then(data => {
            setNews(data.data.news)
        })
        .catch(e => {
            if(e.request.status===0)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#dc2626',
                    position: 'top-end',
                    iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" /></svg>',
                    color : 'white',
                    showConfirmButton: false,
                    timer: 3000,
                    customClass:{
                        icon:'border-0'
                    },
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                
                Toast.fire({
                    title: 'خطا در برقراری ارتباط با سرور'
                })
            }
        })
    },[])

    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 gap-y-4 py-5 px-2">
                {news && news.map(news => (
                    <Link to={`/detailnews/${news.id}`} className="cursor-default lg:cursor-pointer bg-white rounded-lg shadow-md border border-gray-300" key={news.id}>
                        <div className="flex items-center">
                            <div className="w-4/12">
                                <img src={news.pic} className="w-24 h-20 object-cover rounded-r-lg" alt="" />
                            </div>
                            <div className="p-2 flex flex-col gap-y-1 w-8/12">
                                <div className="text-sm">{news.title}</div>
                            </div>
                        </div>
                    </Link>
                ))}
                
            </div>
            <div className="flex justify-center mb-20 lg:mb-0">
                <Link to={"/news"} className="text-blue-500">
                    بیشتر...
                </Link>
            </div>
        </>
    )
}

export default Home