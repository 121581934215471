import { LoginContext } from "../Context/LoginContext"

const LoginProvider = ({children,value}) => {
    return (
        <LoginContext.Provider value={{...value}}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginProvider