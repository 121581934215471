import axios from "axios"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import Loading from "../../components/Student/Loading"

const Protests = () => {

    const [protests,setProtests] = useState([])
    const [lesson,setLesson] = useState(null)
    const [chapter,setChapter] = useState(0)
    const [score,setScore] = useState(null)
    const [description,setDescription] = useState(null)
    const [openProtest,setOpenProtest] = useState(0)
    const [lessons,setLessons] = useState()
    const [loading,setLoading] = useState(true)
    const [errors,setErrors] = useState({
        protest_description : null,
        protest_score : null,
        protest_lesson : null
    })
    const [closedForm,setClosedForm] = useState(true)

    const [closedMessage,setClosedMessage] = useState(null)

    const [disableDelete,setDisableDelete] = useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/student/get-lessons`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => setLessons(data.data.units))
        axios.get(`${process.env.REACT_APP_AddressIP}/api/student/protests`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.data.code === 200)
            {
                setClosedForm(false)
                setProtests([...data.data.protests])
                setLoading(false)
            }
            if(data.data.code === 401){
                setClosedForm(true)
                setProtests([...data.data.protests])
                setLoading(false)
                setDisableDelete(true)
                setClosedMessage(data.data.message)
            }
        }).catch(() => '')
    },[])

    const handleProtest = () => {
        setErrors({
            protest_description : null,
            protest_score : null,
            protest_lesson : null
        })
        setLoading(true)
        if(!lesson)
        {
            setErrors((old) => ({...old,protest_lesson : 'فیلد درس اجباری است'}))
            setLoading(false)
        }
        if(lesson!= null && (lesson.is_chapter === 0 || chapter !== 0)){
        axios.post(`${process.env.REACT_APP_AddressIP}/api/student/protests`,{
            course_id:lesson.id,
            protest_description : description,
            chapter,
            protest_score:score
        },{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(data => {
            if(data.data.code === 200)
            {
                setProtests((oldProtests) => [...oldProtests,{...lesson,id : data.data.id,chapter : chapter,protest_description : description,protest_score : score,state:0,created_at : data.data.created_at}])
                setChapter(null) 
                setDescription(null)
                setScore(null)
                setLesson(null)
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#16a34a',
                    position: 'top-end',
                    color : 'white',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                  
                  Toast.fire({
                    title: data.data.message
                  })
                  setLoading(false)
            }
        }).catch(e => {
            if(e.response.status === 403)
                {
                    const Toast = Swal.mixin({
                        toast: true,
                        background : '#dc2626',
                        position: 'top-end',
                        color : 'white',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                      
                      Toast.fire({
                        title: e.response.data.message
                      })
                      setLoading(false)
                }
            if(e.response.status === 422){
                setErrors((old) => ({...old,...e.response.data.errors}))
                setLoading(false)
            }    
        })
        }
    }

    const handleRemoveProtest = (protest) => {
        Swal.fire({
            title: '<span class="text-sm">آیا اعتراض مورد نظر حذف شود؟</span>',
            showDenyButton: true,
            confirmButtonText: 'حذف',
            denyButtonText: `انصراف`,
            denyButtonColor : '#9ca3af',
            confirmButtonColor : '#dc2626',
            customClass : 'text-sm'
            
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setLoading(true)
                axios.delete(`${process.env.REACT_APP_AddressIP}/api/student/protests/${protest.id}`,{
                    headers : {
                        Authorization : `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(data => {
                    setProtests(protests.filter(oldProtest => protest !== oldProtest));
                    const Toast = Swal.mixin({
                        toast: true,
                        background : '#16a34a',
                        position: 'top-end',
                        color : 'white',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                      
                      Toast.fire({
                        title: data.data.message
                      })
                      setLoading(false)
                })
            } else if (result.isDenied) {
            }
          })
        
    }

    const handleLesson = (e) => {
        setChapter(0)
        setLesson(JSON.parse(e.target.value))
    }

    const handleOpenProtest = (protestId) => {
        setOpenProtest(openProtest == protestId ? 0 : protestId)
    }

    const handleBgProtest = (state) => {
        switch(state){
            case 2:
            return 'bg-red-100'
                case 1:
            return 'bg-green-100'
                default:
            return 'bg-gray-50'
        }
    }

    return (
        <>
            <div className=" z-30 sticky top-14 w-full border-t shadow-sm right-0">
                <div className="bg-white flex justify-center items-center p-1 gap-x-1 px-4 my-0 w-full ">
                    <div className="flex items-center gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                        </svg>
                    </div>
                    <span>اعتراضات</span>
                </div>
            </div>  
            <div className="flex flex-col gap-y-4 items-center pt-28 px-5 pb-20">
                {!loading && closedForm &&
                    <div className="bg-amber-100 text-sm shadow-md rounded-xl w-full text-center p-4">
                        {closedMessage}
                    </div>
                }
                {!closedForm && 
                    <div className="flex flex-col gap-y-3 bg-white w-full p-4 rounded-xl shadow-md">
                        <div>
                            <select onChange={(e) => handleLesson(e)} className={`${errors.protest_lesson ? 'border-red-600' : 'border-gray-300'} border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}>
                                <option selected={!lesson} value="">درس</option>
                                {lessons && lessons.map(lesson => (
                                    <option value={JSON.stringify(lesson)}>{lesson.lesson_name}</option>
                                ))}
                            </select>
                        </div>
                        {errors.protest_lesson &&
                            <div className="text-red-600 text-sm flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.protest_lesson}</span>
                            </div>
                        }
                        {
                        lesson && lesson.is_chapter ?
                            <div className="border mb-5 border-gray-300 overflow-hidden shadow-sm flex justify-evenly rounded-md text-gray-500 text-sm ">
                                <button className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter === 1 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(1)}>1</button>
                                <button className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter === 2 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(2)}>2</button>
                                <button className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter === 3 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(3)}>3</button>
                                <button className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter === 4 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(4)}>4</button>
                                <button className={`text-center cursor-default w-3/12 py-1.5  ${chapter === 5 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(5)}>5</button>
                            </div>
                            :''
                        }
                        <div>
                            <input type="number" value={score == null ? '' : score} placeholder="نمره" onChange={(e) => setScore(e.target.value)} className={`${errors.protest_score ? 'border-red-600' : 'border-gray-300'} border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                        </div>
                        {errors.protest_score &&
                            <div className="text-red-600 text-sm flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.protest_score}</span>
                            </div>
                        }
                        <textarea value={description == null ? '' : description} onChange={(e) => setDescription(e.target.value)} className={`${errors.protest_description ? 'border-red-600' : 'border-gray-300'} border outline-none py-1 px-2 text-sm h-20 resize-none text-gray-500 focus:border-sky-400 rounded-md shadow-sm mt-1 block w-full`} placeholder="توضیحات..."></textarea>
                        {errors.protest_description &&
                            <div className="text-red-600 text-sm flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.protest_description}</span>
                            </div>
                        }
                        <div className="flex justify-center">
                            <button onClick={handleProtest} className="cursor-default bg-sky-400 text-white rounded-md shadow hover:bg-sky-600 px-4 py-2">ثبت اعتراض</button>
                        </div>
                        
                    </div>
                }
                {loading && <Loading/>}
                {!loading && 
                    <div className="flex flex-col gap-y-4 w-full">
                        {protests && protests.map(protest => (
                            <div key={protest.id} onClick={() => handleOpenProtest(protest.id)} className="bg-white shadow-md w-full overflow-hidden rounded-xl">
                                <div className={`${handleBgProtest(protest.state)} flex justify-between items-center border-b border-gray-300 py-1 px-2`}>
                                    <div className="flex gap-x-1.5 items-center">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                            </svg>
                                        </span>
                                        <div className="text-sm py-1 truncate w-full ">
                                        {protest.lesson_name.slice(0,23)}
                                        {protest.lesson_name.length > 22 ? '...' : ''}
                                    </div>
                                    </div>
                                <div className="flex gap-x-2 items-center justify-center">
                                <span className="text-xs">{protest.state !== 0 ? protest.final_score == null ? protest.protest_score : protest.final_score : protest.created_at}</span>
                                    {protest.state === 0 ?
                                        <span className="" >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </span>
                                    : protest.state === 2 ?
                                        <span className="" >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-red-500">
                                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                    : protest.state === 1 ?
                                        <span className="" >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-green-500">
                                                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                            </svg>
                                        </span> : ''
                                    }
                                    
                                    {protest.state === 0 && !disableDelete? 
                                    <div onClick={() => handleRemoveProtest(protest)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </div> :''
                                }
                                <div className="flex justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`transition-all duration-300 ${openProtest === protest.id ? 'rotate-180' : 'rotate-0'} w-4 h-4`}>
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                </div>
                            </div>
                            <div className={`${openProtest === protest.id ? '' : 'hidden'}`}>
                            {protest.chapter !== 0 ? 
                                <div className="mt-2 px-4 flex items-center gap-x-2">
                                    <span className="text-xs whitespace-nowrap text-gray-400">پودمان</span>
                                    <div className="border-t-2 border-dotted border-gray-300 w-full"></div>
                                    <span className="text-sm whitespace-nowrap">{protest.chapter}</span>
                                </div>: ''
                            }
                                <div className="mt-2 px-4 flex items-center gap-x-2">
                                    <span className="text-xs whitespace-nowrap text-gray-400">نمره</span>
                                    <div className="border-t-2 border-dotted border-gray-300 w-full"></div>
                                    <span className="text-sm whitespace-nowrap">{protest.protest_score}</span>
                                </div>
                                {protest.protest_description && <>
                                    <div className="py-2 px-4 flex items-center gap-x-2">
                                        <span className="text-xs whitespace-nowrap text-gray-400">توضیحات </span>
                                        <div className="border-t-2 border-dotted border-gray-300 w-full"></div>
                                    </div>
                                    <div className="text-xs px-4 text-justify mb-3 break-words">{protest.protest_description}</div>
                                </>}
                                {protest.state !== 0 ? 
                                <>
                                    <div className="border-t border-gray-300"></div>
                                    <div className="mb-2">
                                        <div className="pt-2 px-4 flex items-center gap-x-2">
                                            <span className="text-xs whitespace-nowrap text-gray-400">نمره بازبینی شده</span>
                                            <div className="border-t-2 border-dotted border-gray-300 w-full"></div>
                                            <span className="text-sm whitespace-nowrap">{protest.final_score == null ? protest.protest_score : protest.final_score}</span>
                                        </div>
                                        {protest.teacher_description != null ? 
                                        <>
                                            <div className="py-2 px-4 flex items-center gap-x-2">
                                                <span className="text-xs whitespace-nowrap text-gray-400">توضیحات دبیر </span>
                                                <div className="border-t-2 border-dotted border-gray-300 w-full"></div>
                                            </div>
                                            <div className="text-xs px-4 text-justify ">{ protest.teacher_description}</div>
                                        </> :''
                                        }
                                        <div className="pt-2 px-4 flex items-center gap-x-2">
                                            <span className="text-xs whitespace-nowrap text-gray-400">تاریخ بروزرسانی</span>
                                            <div className="border-t-2 border-dotted border-gray-300 w-full"></div>
                                            <span className="text-sm whitespace-nowrap">{protest.updated_at}</span>
                                        </div>

                                    </div>
                                </>
                                :''
                                }
                            </div>
                            
                        </div>
                        ))}
                    </div>
                }
            </div>
        </>
    )
}

export default Protests