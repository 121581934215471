import { useEffect, useState } from "react"
import { AdminContext } from "../Context/AdminContext"

const AdminProvider = ({children}) => {

    const [blur,setBlur] = useState(false)
    const [blurFiles,setBlurFiles] = useState(false)
    const adminPath = process.env.REACT_APP_AdminPath;
    const [blurClass,setBlurClass] = useState(null);
    useEffect(() => {
        setBlurClass(`${blur ? 'enableBlur footerBlur h-full' : ''} ${blurFiles ? 'enableFilesBlur': 'h-full'}`)
    },[blur,blurFiles])

    return (
        <AdminContext.Provider value={{setBlur,adminPath,setBlurFiles,blurFiles}}>
            {blur && <div className="w-full h-full fixed top-0 z-100"></div>}
            <div className={blurClass}>
                {children}
            </div>
        </AdminContext.Provider>
    )
}

export default AdminProvider