import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { AdminContext } from '../../../components/Context/AdminContext'
import Toast from "../../../components/Toast"
import Table from "../../../components/Table"
import { Link } from "react-router-dom"

const IndexStudent = () => {

    const [students,setStudents] = useState([])
    const [more,setMore] = useState(0)
    const [moreDetail,setMoreDetail] = useState(0)
    const {adminPath,setBlur} = useContext(AdminContext)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/management-users`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.data.code === 200){
                setStudents(data.data.students)
            }
        })
        .catch(e => {
            if(e.request.status){
                Toast('error',e)
            }
        })
    },[])

    const deleteStudent = (id) => {
        axios.delete(`${process.env.REACT_APP_AddressIP}/api/admin/management-users/${id}`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            setStudents((old) => old.filter(student => student.id !== id))
            Toast('success',data)
        })
        .catch(e => {
            Toast('error',e)
        })
    }

    const handleActive = (id,active) => {
        axios.post(`${process.env.REACT_APP_AddressIP}/api/admin/management-users/${id}/active`,{
            active
        },{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.data.code === 200){
                setStudents(old => old.map(student => student.id === id ? {...student,active} : student))
                Toast('success',data)
            }
        })
        .catch(e => {
            if(e.request.status){
                Toast('error',e)
            }
        })
    }

    return(
        <div className="flex flex-col items-center px-4 pt-5 pb-20 ">
            {!Array.isArray(students) && 
                <div className="bg-white p-2 rounded-md shadow text-center w-full lg:w-4/12">
                    {students}
                </div>
            }
            {Array.isArray(students) &&
                <div className="bg-white rounded-xl w-full">
                <div></div>
                <div>
                    {students.map(student => (
                    <div className="pr-2 py-2.5 border-b last:border-b-0 flex relative">
                        <div className="flex flex-col gap-y-1 w-full">
                            <div className="flex justify-between items-center gap-y-1">
                                <div className="text-sm w-44 truncate">{student.first_name+' '+student.last_name}</div>
                                <div className="text-xs justify-self-center text-end w-28 ">{student.nation_code}</div>
                            </div>  
                            <div className="flex justify-between gap-x-2 items-center">
                                <div className="text-[0.6rem] justify-self-end text-white truncate max-w-[5rem] bg-[#ffb703] p-0.5 px-2 rounded-full">{student.grade}</div>
                                <div className="text-[0.6rem] col-span-2 text-white truncate max-w-[10rem] bg-color-primary-300 p-0.5 px-2 rounded-full">{student.field}</div>
                            </div>
                        </div>
                        <div className="flex justify-end items-center px-0.5 relative">
                            <div className={`${moreDetail === student.id ? 'block' : 'hidden'} z-[60] fixed top-0 left-0 w-full flex justify-center items-start pt-20 h-full`}>
                            <div className={` ${moreDetail === student.id ? 'block' : 'hidden'} fixed w-full h-full top-0 left-0 z-0`} onClick={() => setMoreDetail(0)}></div>
                            
                                <div className="bg-white p-4 overflow-y-scroll h-96 rounded-xl border border-color-primary-400 w-10/12 lg:w-3/12 text-sm flex flex-col gap-y-2 z-10">
                                    <div>
                                        <span>نام : </span>
                                        <span>{student.first_name}</span>
                                    </div>
                                    <div>
                                        <span>نام خانوادگی : </span>
                                        <span>{student.last_name}</span>
                                    </div>
                                    <div>
                                        <span>نام پدر : </span>
                                        <span>{student.father_name}</span>
                                    </div>
                                    <div>
                                        <span>تاریخ تولد : </span>
                                        <span>{student.birthday}</span>
                                    </div>
                                    <div>
                                        <span>تاریخ تولد : </span>
                                        <span>{student.born_place}</span>
                                    </div>
                                    <div>
                                        <span>ملیت : </span>
                                        <span>{student.nationality}</span>
                                    </div>
                                    <div>
                                        <span>مذهب : </span>
                                        <span>{student.religion}</span>
                                    </div>
                                    <div>
                                        <span>چپ دست : </span>
                                        <span>{student.left_hand ? 'بله' : 'خیر'}</span>
                                    </div>
                                    <div>
                                        <span>توانایی خاص : </span>
                                        <span>{student.special_ability}</span>
                                    </div>
                                    <div>
                                        <span>مقام : </span>
                                        <span>{student.rank}</span>
                                    </div>
                                    <div>
                                        <span>بیماری خاص : </span>
                                        <span>{student.special_disease}</span>
                                    </div>
                                    <div>
                                        <span>پایه : </span>
                                        <span>{student.grade}</span>
                                    </div>
                                    <div>
                                        <span>رشته : </span>
                                        <span>{student.field}</span>
                                    </div>
                                    <div>
                                        <span>مدرسه سال نهم : </span>
                                        <span>{student.school}</span>
                                    </div>
                                    <div>
                                        <span>معدل : </span>
                                        <span>{student.average}</span>
                                    </div>
                                    <div>
                                        <span>انضباط : </span>
                                        <span>{student.discipline_grade}</span>
                                    </div>
                                    <div>
                                        <span>شماره همراه دانشجو : </span>
                                        <span>{student.phone}</span>
                                    </div>
                                    <div>
                                        <span>شماره پدر : </span>
                                        <span>{student.father_phone}</span>
                                    </div>
                                    <div>
                                        <span>شماره مادر : </span>
                                        <span>{student.mother_phone}</span>
                                    </div>
                                    <div>
                                        <span>شماره اطلاع رسانی اولیاء : </span>
                                        <span>{student.sms_phone}</span>
                                    </div>
                                    <div>
                                        <span>شماره تلفن منزل : </span>
                                        <span>{student.home_phone}</span>
                                    </div>
                                    <div>
                                        <span>شماره تماس ضروری : </span>
                                        <span>{student.emergency_phone}</span>
                                    </div>
                                    <div>
                                        <span>آدرس : </span>
                                        <span>{student.address}</span>
                                    </div>
                                    <div>
                                        <span>وضعیت محل سکونت : </span>
                                        <span>{student.home_state}</span>
                                    </div>
                                    <div>
                                        <span>شماره پستی : </span>
                                        <span>{student.postal_code}</span>
                                    </div>
                                    <div>
                                        <span>پدر فوت شده : </span>
                                        <span>{student.father_dead ? 'بله' : 'خیر'}</span>
                                    </div>
                                    <div>
                                        <span>شغل پدر : </span>
                                        <span>{student.father_job}</span>
                                    </div>
                                    <div>
                                        <span>آدرس محل کار : </span>
                                        <span>{student.father_job_address}</span>
                                    </div>
                                    <div>
                                        <span>مادر فوت شده : </span>
                                        <span>{student.mother_dead ? 'بله' : 'خیر'}</span>
                                    </div>
                                    <div>
                                        <span>شغل مادر : </span>
                                        <span>{student.mother_job}</span>
                                    </div>
                                    <div>
                                        <span>آدرس محل کار : </span>
                                        <span>{student.mother_job_address}</span>
                                    </div>
                                    <div>
                                        <span>والدین طلاق گرفته اند : </span>
                                        <span>{student.divorced ? 'بله' : 'خیر'}</span>
                                    </div>
                                    <div>
                                        <span>زندگی با : </span>
                                        <span>{student.life_with}</span>
                                    </div>
                                    <div>
                                        <span>تعداد برادر : </span>
                                        <span>{student.brothers}</span>
                                    </div>
                                    <div>
                                        <span>تعداد خواهر : </span>
                                        <span>{student.sisters}</span>
                                    </div>
                                    <div>
                                        <span>تحت پوشش : </span>
                                        <span>{student.covered_by}</span>
                                    </div>
                                    <div>
                                        <span>وضعیت ایثار گری : </span>
                                        <span>{student.sacrifice}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={` ${more === student.id ? 'block' : 'hidden'} fixed w-full h-full top-0 left-0 z-20`} onClick={() => setMore(0)}></div>
                            <div className={`${more === student.id ? 'block' : 'hidden'} z-50 absolute left-0 `}>
                                <div className={`bg-gray-50 overflow-hidden shadow-md bg-opacity-70 z-10 backdrop-blur-sm rounded-xl flex flex-col justify-center w-40`}>
                                <div className="border-b w-full">
                                    {student.active ?
                                        <button onClick={() => {
                                            setMore(0)
                                            handleActive(student.id,0)
                                        }} className="p-2 flex gap-x-1 cursor-default lg:cursor-pointer text-sm hover:bg-white w-full">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </span>
                                            <span>غیر فعال کردن</span>
                                        </button>
                                        :
                                        <button onClick={() => {
                                            setMore(0)
                                            handleActive(student.id,1)
                                        }} className="p-2 cursor-default lg:cursor-pointer text-sm flex gap-x-1 hover:bg-white w-full">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </span>
                                            <span>فعال کردن</span>
                                        </button>
                                    }
                                </div>
                                <div className="border-b w-full">
                                    <button onClick={() => {
                                            setMore(0)
                                            setMoreDetail(student.id)
                                    }} className="flex gap-x-1 text-sm cursor-default lg:cursor-pointer hover:bg-white w-full p-2">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </span>
                                        <span>مشاهده جزئیات</span>
                                    </button>
                                </div>
                                <div className="border-b w-full">
                                    <Link to={`/${adminPath}/student/${student.id}/edit`} className="flex gap-x-1 text-sm hover:bg-white w-full p-2">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </span>
                                        <span>ویرایش</span>
                                    </Link>
                                </div>
                                <button onClick={() => {
                                        setMore(0)
                                        deleteStudent(student.id)
                                }} className="text-sm cursor-default lg:cursor-pointer flex gap-x-1 hover:bg-white w-full p-2 text-red-600">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </span>
                                    <span>حذف</span>
                                </button>
                                </div>
                            </div>
                            <button className="cursor-default lg:cursor-pointer" onClick={() => {
                                setMore(student.id)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            }
        </div>
    )
}

export default IndexStudent