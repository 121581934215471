const Padding = ({children,className}) => {
    return (
        <div className={className}>
            <div className="relative h-full overflow-y-scroll lg:overflow-y-auto">
                {children}
            </div>
        </div>
    )
}

export default Padding