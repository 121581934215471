import { useContext, useState } from "react"
import Calendar from "../../../components/Main/Calendar"
import axios from "axios"
import Swal from "sweetalert2"
import { AdminContext } from "../../../components/Context/AdminContext"
import { useNavigate } from "react-router"

const CreateStaff = () => {

    const {adminPath} = useContext(AdminContext)
    const navigate = useNavigate()
    const [fields,setFields] = useState({
        first_name : '',
        last_name : '',
        father_name : '',
        nation_code : '',
        birthday : '',
        born_place : '',
        married : true,
        personal_code : '',
        education : '',
        education_field : '',
        teach_field : '',
        Responsibility : '',
        employ_date : '',
        teach_over : '',
        overtime : '',
        phone : '',
        home_phone : '',
        postal_code : '',
        address : '',
        account_number : '',
        card_number : '',
        iban : '',
        sacrifice : '',
        children : '',
        employ_state : '',
        active : false
    })
    const [errors,setErrors] = useState({
        first_name : '',
        last_name : '',
        father_name : '',
        nation_code : '',
        birthday : '',
        born_place : '',
        married : true,
        education : '',
        education_field : '',
        teach_field : '',
        Responsibility : '',
        employ_date : '',
        teach_over : '',
        overtime : '',
        phone : '',
        home_phone : '',
        postal_code : '',
        address : '',
        account_number : '',
        card_number : '',
        iban : '',
        sacrifice : '',
        children : '',
        active : false
    }) 

    const handleCheckbox = (field) => {
        handleFields(field,!fields[field])
    }

    const handleFields = (field,value) => {
        setFields({...fields,[field] : value})
    }

    const handleCreate = () => {
        axios.post(`${process.env.REACT_APP_AddressIP}/api/admin/management-staff`,{
            first_name : fields.first_name,
            last_name : fields.last_name,
            father_name : fields.father_name,
            nation_code : fields.nation_code,
            birthday : fields.birthday,
            born_place : fields.born_place,
            married : fields.married,
            personal_code : fields.personal_code,
            education : fields.education,
            education_field : fields.education_field,
            teach_field : fields.teach_field,
            Responsibility : fields.Responsibility,
            employ_date : fields.employ_date,
            teach_over : fields.teach_over,
            overtime : fields.overtime,
            phone : fields.phone,
            home_phone : fields.home_phone,
            postal_code : fields.postal_code,
            address : fields.address,
            account_number : fields.account_number,
            card_number : fields.card_number,
            iban : fields.iban,
            sacrifice : fields.sacrifice,
            children : fields.children,
            active : fields.active,
            employ_state : fields.employ_state
        },{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(data => {
            if(data.request.status === 200)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#afffcc',
                    position: 'top-end',
                    iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-500"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',                    
                    customClass : {
                        icon : 'border-0'
                    },
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                  
                  Toast.fire({
                    title: data.data.message
                  })
                  navigate(`/${adminPath}/staffs`)
            }
        }).catch(e => {
            if(e.request.status === 422)
                {
                    setErrors({...e.response.data.errors})
                }else if(e.request.status === 0)
                {
                    const Toast = Swal.mixin({
                        toast: true,
                        background : '#dc2626',
                        position: 'top-end',
                        iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" /></svg>',
                        color : 'white',
                        showConfirmButton: false,
                        timer: 3000,
                        customClass:{
                            icon:'border-0'
                        },
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                      
                      Toast.fire({
                        title: 'خطا در برقراری ارتباط با سرور'
                      })
                }
        })
    }

    return(
        <div className="p-4">
            <div className={`relative mb-16 bg-white shadow-md border-t transition-all overflow-hidden duration-700 rounded-md pb-8 `}>
                <div className="grid lg:grid-cols-3  md:grid-cols-2 gap-x-4 grid-cols-1 justify-items-center xl:px-11 px-4 gap-y-6 pt-10 text-sm xl:text-base">
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>نام</span>
                                    <span className="text-red-600">*</span>
                                </div>
                                <input onChange={(e) => handleFields('first_name',e.target.value)} type="text" className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>نام خانوادگی</span>
                                    <span className="text-red-600">*</span>
                                </div>
                                <input type="text" onChange={(e) => handleFields('last_name',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>نام پدر</span>
                                </div>
                                <input type="text" onChange={(e) => handleFields('father_name',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>شماره ملی</span>
                                    <span className="text-red-600">*</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('nation_code',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center relative">
                                <div className="w-full mb-2">
                                    <span>تاریخ تولد</span>
                                </div>
                                <Calendar setFields={setFields} field={'birthday'}/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>محل تولد</span>
                                </div>
                                <input type="text" onChange={(e) => handleFields('born_place',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>کد پرسنلی</span>
                                    <span className="text-red-600">*</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('personal_code',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>تحصیلات</span>
                                </div>
                                <select onChange={(e) => handleFields('education',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md disabled:bg-gray-100">
                                    <option value="">تحصیلات مورد نظر را انتخاب کنید</option>
                                    <option value="دیپلم">دیپلم</option>
                                    <option value="فوق دیپلم">فوق دیپلم</option>
                                    <option value="لیسانس">لیسانس</option>
                                    <option value="فوق لیسانس">فوق لیسانس</option>
                                    <option value="دکتوری">دکتوری</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>رشته تحصیلی</span>
                                </div>
                                <select onChange={(e) => handleFields('education_field',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md disabled:bg-gray-100">
                                    <option value="">تحصیلات مورد نظر را انتخاب کنید</option>
                                    <option value="کامپیوتر">کامپیوتر</option>
                                    <option value="معماری">معماری</option>
                                    <option value="حسابداری">حسابداری</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>زمینه تدریس</span>
                                </div>
                                <select onChange={(e) => handleFields('teach_field',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md disabled:bg-gray-100">
                                    <option value="">تحصیلات مورد نظر را انتخاب کنید</option>
                                    <option value="کامپیوتر">کامپیوتر</option>
                                    <option value="معماری">معماری</option>
                                    <option value="حسابداری">حسابداری</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>مسئولیت</span>
                                </div>
                                <input type="text" onChange={(e) => handleFields('Responsibility',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center relative">
                                <div className="w-full mb-2">
                                    <span>تاریخ استخدام</span>
                                </div>
                                <Calendar setFields={setFields} field={'employ_date'}/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>ساعت آموزش</span>
                                </div>
                                <input type="text" onChange={(e) => handleFields('teach_over',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>ساعت آموزش</span>
                                </div>
                                <input type="text" onChange={(e) => handleFields('overtime',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>شماره تلفن</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('phone',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>تلفن منزل</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('home_phone',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>کد پستی</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('postal_code',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>آدرس</span>
                                </div>
                                <input type="text" onChange={(e) => handleFields('address',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>شماره حساب</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('account_number',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>شماره کارت</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('card_number',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>شماره شباء</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('card_number',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>وضعیت ایثارگری</span>
                                    <input type="text" onChange={(e) => handleFields('sacrifice',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>تعداد فرزندان</span>
                                </div>
                                <input type="number" onChange={(e) => handleFields('children',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col justify-center items-center">
                                <div className="w-full mb-2">
                                    <span>وضعیت استخدام</span>
                                </div>
                                <input type="text" onChange={(e) => handleFields('employ_state',e.target.value)} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
                            </div>
                        </div>
                        <div className="flex items-center gap-x-2">
                            <span>وضعیت تأهل</span>
                            <button type="checkbox" onClick={(e) => handleCheckbox('married')} class={`${fields.married ? 'bg-blue-500' : 'bg-gray-200'} cursor-default flex w-8 flex-none lg:cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" role="switch" aria-checked="false" aria-labelledby="switch-1-label`}>
                                <span aria-hidden="true" class={`${fields.married ? 'translate-x-0' : '-translate-x-3.5'} h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out`}></span>
                            </button>
                        </div>
                </div> 
                    <div className="xl:flex justify-end xl:pl-16 px-4 w-full">
                        <button onClick={() => handleCreate()} className="flex gap-x-2 justify-center bg-cyan-400 mt-5 px-4 py-2 rounded-md text-white w-full xl:w-auto disabled:opacity-50 disabled:cursor-not-allowed">
                            <span className="">
                                ثبت
                            </span>
                            
                        </button>
                    </div>
            </div>
        </div>
    )
}

export default CreateStaff