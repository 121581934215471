import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AdminContext } from "../../../components/Context/AdminContext"
import Swal from "sweetalert2"
import axios from "axios"
import Table from "../../../components/Table"
import Toast from "../../../components/Toast"


const IndexStaff = () => {

    const [staffs,setStaffs] = useState([])
    const {adminPath} = useContext(AdminContext)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/management-staff`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.request.status === 200){
                setStaffs(data.data.staffs)
            }
        })
        .catch(e => {
            if(e.request.status===0){
            const Toast = Swal.mixin({
                toast: true,
                background : '#dc2626',
                position: 'top-end',
                color : 'white',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
              
              Toast.fire({
                title: 'خطا در برقراری ارتباط با سرور'
              })
            }else if(e.request.status === 404){
                setStaffs(e.response.data.message)
            }
              
            
        })
    },[])

    const deleteRecord = (id) => {
        axios.delete(`${process.env.REACT_APP_AddressIP}/api/admin/protests/${id}`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            setStaffs((old) => old.filter(state => state.id !== id))
            Toast('success',data)
        })
    }

    const editPath = (id) => {
        return `/${adminPath}/protest/${id}/edit`
    }
 
    return(
        <div className="flex flex-col items-center gap-y-4 px-4 pt-5 pb-20 ">
            {!Array.isArray(staffs) && 
                <div className="bg-white p-2 rounded-md shadow text-center w-full lg:w-4/12">
                    {staffs}
                </div>
            }
            {Array.isArray(staffs) && 
                <Table records={staffs} fields={[
                    {title : '',value :'first_name'},
                    {title : '',value :'last_name'},
                    {title : '',value :'nation_code'},
                ]} uniqueKey={'id'} editPath={editPath} deleteRecord={deleteRecord}/>
            }
        </div>
    )
}

export default IndexStaff