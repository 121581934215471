import axios from "axios"

import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import CreateNews from "./Create"

const IndexNews = () => {



    const [news,setNews] = useState([])

    

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/news`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            setNews(data.data.news)
        })
        .catch(e => {
            if(e.request.status === 0)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#dc2626',
                    position: 'top-end',
                    iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" /></svg>',
                    color : 'white',
                    showConfirmButton: false,
                    timer: 3000,
                    customClass:{
                        icon:'border-0'
                    },
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                  
                  Toast.fire({
                    title: 'خطا در برقراری ارتباط با سرور'
                  })
            }
        })
    },[])


    const deleteNews = (id) => {
        
        axios.delete(`${process.env.REACT_APP_AddressIP}/api/admin/news/${id}`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            setNews(old => old.filter(news => news.id !== id))
            const Toast = Swal.mixin({
                toast: true,
                background : '#afffcc',
                position: 'top-end',
                iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-500"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',                    
                customClass : {
                    icon : 'border-0'
                },
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
              
              Toast.fire({
                title: data.data.message
              })
        })
        .catch()
    }

    
    
    return (
        <div className="flex flex-col gap-y-4 items-center px-5 pt-12 pb-20 relative">
            <CreateNews setNews={setNews}/>
            <div className="flex flex-col gap-y-4 w-full lg:w-4/12 lg:max-w-xl">
                {news && news.map(news => (
                    <div key={news.id}  className="bg-white shadow-md w-full overflow-hidden rounded-xl">
                        <div className={` flex justify-between items-center border-b border-gray-300 py-1 px-2`}>
                            <div className="flex gap-x-1.5 items-center">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                                    </svg>
                                </span>
                                <div className="text-sm py-1 truncate w-full ">
                                {news.title.slice(0,23)}
                                {news.title.length > 22 ? '...' : ''}
                            </div>
                            </div>
                        <div className="flex gap-x-2 items-center justify-center">
                            <Link to={`/${process.env.REACT_APP_AdminPath}/news/${news.id}`} className="cursor-default lg:cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                </svg>
                            </Link>
                            <button onClick={() => deleteNews(news.id)} className="cursor-default lg:cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    )
}

export default IndexNews