import { useEffect, useState } from "react"
import Image2 from "../../images/qni-110x75.jpg"
import { useParams } from "react-router"
import axios from "axios"
import Swal from "sweetalert2"

const DetailNews = () => {



    const [news,setNews] = useState(null)

    const {id} = useParams()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/single-news?id=${id}`)
        .then(data => {
            setNews(data.data.news)
        })
        .catch(e => {
            if(e.request.status===0)
            {
                const Toast = Swal.mixin({
                    toast: true,
                    background : '#dc2626',
                    position: 'top-end',
                    iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" /></svg>',
                    color : 'white',
                    showConfirmButton: false,
                    timer: 3000,
                    customClass:{
                        icon:'border-0'
                    },
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                
                Toast.fire({
                    title: 'خطا در برقراری ارتباط با سرور'
                })
            }
        })
    },[])

    return (
        
        <>
            {news &&
                <div className="flex flex-col justify-center items-center ">
                    <div className="bg-white rounded-lg mt-5 w-11/12 lg:w-4/12 mb-28 overflow-hidden border border-gray-300">
                        <div>
                            <div className="">
                                <img src={news.pic} className="w-full rounded-t-lg h-44 lg:h-72 object-cover" alt="" />
                            </div>
                            <div className="px-4 font-bold text-xl py-3 pb-2">
                                {news.title}
                            </div>
                            <div className="mb-5 px-4">
                                <div className="text-sm text-justify break-words">
                                    {news.content}
                                </div>
                                <div className="flex flex-col gap-y-2 text-sky-400 pt-10 text-sm">
                                    {news.attachments.map(attachment=> (
                                        <a download className="w-20 cursor-default lg:cursor-pointer" href={attachment} key={attachment}>دریافت فایل</a>
                                    ))}
                                </div>
                            </div>
                            <div className="flex justify-end pb-2 pl-4 text-gray-500 text-sm items-center">
                                <div>
                                    <span>{news.date}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
        
    )
}

export default DetailNews