import { Route, Routes } from "react-router"
import Login from "../pages/Main/Login"
import Register from "../pages/Main/Register"
import NotFound from "../pages/Main/NotFound"
import Home from "../pages/Main/Home"
import News from "../pages/Main/News"
import Header from "../components/Main/Header"
import MobileMenu from "../components/Main/MobileMenu"
import Contact from "../pages/Main/Contact"
import Majors from "../pages/Main/Majors"
import Graduates from "../pages/Main/Graduates"
import { useState } from "react"
import Padding from "../components/Padding"
import Menu from "../components/Main/Menu"
import DetailNews from "../pages/Main/DetailNews"


const Main = () => {
    const [isOpenMenu,setIsOpenMenu] = useState(false)
    

    return (
        <>
                <Menu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu}/>
                <Padding className={` fixed bottom-0 w-full h-full overflow-y-scroll lg:overflow-y-hidden`}>
                <Header isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu}/>
                    <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="/news" element={<News />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/majors" element={<Majors />} />
                        <Route path="/graduates" element={<Graduates />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/detailnews/:id" element={<DetailNews />} />
                        <Route path="*" element={<NotFound />}/>
                    </Routes>
                    <MobileMenu />
                </Padding>
        </>
    )
}

export default Main