import Image2 from "../../images/qni-110x75.jpg"

const Contact = () => {
    return (
        <div className="w-full lg:flex lg:justify-center">
            <div className="flex flex-col justify-center items-center w-full lg:w-5/12">
                <div className="bg-white rounded-lg mt-5 w-11/12 mb-28 overflow-hidden border border-gray-300">
                <div>
                    <div className="mb-5">
                        <img src={Image2} className="w-full rounded-t-lg" alt="" />
                    </div>
                    <div className="mb-16 px-4">
                        <div className="text-sm text-justify">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                        </div>
                    </div>
                </div>
                <div className="bg-[#fcfcfc]">
                    <div className="my-5 border-t border-gray-200"></div>
                        <div className="mb-5 px-4">
                            <div className="flex gap-x-1 items-center mb-2 text-blue-950">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                                <span className="text-sm">ایمیل</span>
                            </div>
                            <div className="text-sm text-gray-700 text-center">mail@abedini-school.ir</div>
                        </div>
                        <div  className="my-5 border-t border-gray-200"></div>
                        <div className="mb-5 px-4">
                            <div className="flex gap-x-1 items-center mb-2 text-blue-950">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                </svg>
                                <span className="text-sm">تلفن</span>
                            </div>
                            <div className="text-sm text-gray-700 text-center">07132227686 – 07132241714</div>
                        </div> 
                        <div  className="my-5 border-t border-gray-200"></div>
                        <div className="px-4 pb-5">
                            <div className="flex gap-x-1 items-center mb-2 text-blue-950">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                                <span className="text-sm">آدرس</span>
                            </div>
                            <div className="text-sm text-gray-700 px-7">شیراز – خیابان فردوسی – حدفاصل سه راه مدبر و چهارراه هجرت – نبش کوچه 3</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact