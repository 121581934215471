import { useContext, useEffect, useState } from "react"
import { AdminContext } from "../../../components/Context/AdminContext"
import axios from "axios"
import Swal from "sweetalert2"
import Toast from "../../../components/Toast"

const IndexProtest = () => {
    const [protests,setProtests] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/protests`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            if(data.request.status === 200){
                setProtests(data.data.protests)
            }
        })
        .catch(e => {
            if(e.request.status===0){
                Toast('error',e)
            }else if(e.request.status === 404){
                setProtests(e.response.data.message)
            }
              
            
        })
    },[])

    const deleteProtest = (id) => {
        axios.delete(`${process.env.REACT_APP_AddressIP}/api/admin/protests/${id}`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => {
            setProtests((old) => old.filter(student => student.id !== id))
            Toast('success',data)
        })
    }

    return (
        <>
            <div dir="rtl" className="flex flex-col print:items-start items-center gap-y-4 px-4 pt-5 print:pt-0 pb-20">
            <div className="bg-white px-4 py-2 text-lg font-bold rounded-lg print:w-full print:text-center">
                لیست اعتراضات
            </div>
            {!Array.isArray(protests) && 
                <div className="bg-white p-2 rounded-md shadow text-center w-full lg:w-4/12">
                    {protests}
                </div>
            }
            {console.log(protests)}
            {Array.isArray(protests) && protests.map(protest => (
                
                <div key={protest.id} className="w-full print:break-inside-avoid item-list print:even:bg-gray-200 lg:max-w-5xl rounded-md shadow flex lg:flex-row flex-col gap-y-4  items-center print:items-start justify-between bg-white p-4">
                        <div className="flex lg:flex-row flex-col w-full gap-y-1 lg:justify-start print:justify-between print:w-full lg:w-full print:flex-row">
                            <div className="flex flex-col print:flex-row lg:flex-row justify-between lg:justify-start print:flex">
                                <div className="flex w-full lg:w-auto print:w-auto justify-between">
                                    <div className=" py-0.5 px-4 flex items-center gap-x-2">
                                        <span className="text-sm whitespace-nowrap">{protest.student && protest.student.first_name+' '+protest.student.last_name}</span>
                                    </div>
                                    <div className=" py-0.5 px-4 lg:flex print:flex items-center gap-x-2">
                                        <span className="text-sm whitespace-nowrap">{protest.student && protest.student.nation_code}</span>
                                    </div>
                                </div>
                                <div className=" py-0.5 px-4 flex print:flex items-center gap-x-2 print:break-words print:w-64">
                                    <span className="text-sm">{protest.course.lesson_name}</span>
                                </div>
                            </div>
                            {protest.chapter != 0? 
                                <div className="  py-0.5 px-4 flex items-center gap-x-2">
                                    <span className="text-xs whitespace-nowrap text-gray-600 lg:hidden print:hidden">پودمان</span>
                                    <div className="border-t-2 border-dotted border-gray-300 w-6/12 lg:hidden print:hidden"></div>
                                    <div className="border border-gray-300 overflow-hidden shadow-sm flex items-center justify-start rounded-lg text-gray-500 text-xs lg:text-sm w-48 lg:w-auto print:w-auto">
                                        <button className={`text-center cursor-default w-full h-5 lg:w-8 lg:h-full lg:py-1 print:w-8 print:h-full print:py-1 bg-sky-400 print:bg-gray-600 text-white`} >{protest.chapter}</button>
                                    </div>
                                </div>
                            : ''
                            }
                        </div>
                        <div className="flex gap-x-2 w-full lg:w-auto">
                        <button onClick={() => deleteProtest(protest.id)} className="lg:cursor-pointer cursor-default bg-red-500 text-white lg:p-2 rounded-md w-full p-2 flex items-center justify-center text-xs">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span className="lg:hidden">
                                حذف
                            </span>
                        </button>
                    </div>
                    </div>
            ))}
        </div>
        </>
    )
}

export default IndexProtest