import { Link, NavLink } from "react-router-dom"
import Image2 from "../../images/login-title.jpg"
import { useEffect } from "react"

const Menu = ({isOpenMenu,setIsOpenMenu}) => {

    return (
        <>
            <div onClick={() => setIsOpenMenu(false)} className={`${isOpenMenu ? 'block' : 'hidden'} z-30 fixed top-0 left-0 backdrop-blur-sm w-full h-full flex justify-center items-center`}></div>
            <div className="fixed top-0 w-full z-30">
                <div className="relative">
                    <div onClick={() => setIsOpenMenu(false)} className={`${isOpenMenu ? '' : 'hidden'} fixed blur top-0 left-0 bg-opacity-40 bg-gray-100 w-full h-full z-20`}>
                    </div>
                    <div className={`bg-white w-64 shadow-lg transition-all duration-300 absolute h-screen overflow-hidden z-40 ${isOpenMenu ? 'translate-x-0' : 'translate-x-72'}`}>
                        <div className="flex flex-col justify-center items-center gap-y-2 mb-5">
                            <img src={Image2} className="w-full h-28  object-cover" alt="" />
                        </div>
                        <div className="p-4 flex flex-col gap-y-4">
                            {/* <Link to={"/"} className="flex items-center gap-x-2 justify-center bg-purple-700 px-4 py-2 rounded-md text-white">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </span>
                                <span className="text-sm">ویرایش</span>
                            </Link> */}
                            <NavLink onClick={() => setIsOpenMenu(false)} to={"/"} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2'}>
                                    <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>
                                </span>
                                <span className="text-sm">خانه</span>
                            </NavLink>
                            <NavLink onClick={() => setIsOpenMenu(false)} to={"/news"} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2'}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                                    </svg>
                                </span>
                                <span className="text-sm">اخبار</span>
                            </NavLink>
                            <NavLink onClick={() => setIsOpenMenu(false)} to={"/contact"} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2'}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                    </svg>
                                </span>
                                <span className="text-sm">ارتباط با ما</span>
                            </NavLink>
                            <NavLink onClick={() => setIsOpenMenu(false)} to={"/majors"} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2'}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                    </svg>
                                </span>
                                <span className="text-sm">معرفی رشته ها</span>
                            </NavLink>
                            <NavLink onClick={() => setIsOpenMenu(false)} to={"/login"} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2'}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                    </svg>
                                </span>
                                <span className="text-sm">ورود</span>
                            </NavLink>
                            <NavLink onClick={() => setIsOpenMenu(false)} to={"/register"} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2'}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </span>
                                <span className="text-sm">ثبت نام</span>
                            </NavLink>
                            <NavLink onClick={() => setIsOpenMenu(false)} to={"/graduates"} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2'}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                    </svg>
                                </span>
                                <span className="text-sm">فارغ التحصیلان</span>
                            </NavLink>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    )
}

export default Menu