import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./routes/Main";
import Student from "./routes/Student";
import { useState } from "react";
import { LoginContext } from './components/Context/LoginContext'
import LoginProvider from "./components/Provider/LoginProvider";
import Admin from "./routes/Admin";
import AdminProvider from "./components/Provider/AdminProvider";


function App() {
  const [isLogin,setIsLogin] = useState(localStorage.getItem('token') ? true : false)

  return (
    <div className="overflow-y-scroll lg:overflow-y-auto h-full">
    <BrowserRouter>
      <LoginProvider value={{isLogin,setIsLogin}}>
        <Routes>
          <Route path={`/${process.env.REACT_APP_AdminPath}/*`} element={
            <AdminProvider>
              <Admin />
            </AdminProvider>
          }/>
          <Route path="/dashboard/*" element={<Student />}/>
          <Route path="/*" element={<Main />}/>
        </Routes>
      </LoginProvider>
    </BrowserRouter>
    </div>
  );
}

export default App;
