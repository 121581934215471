
const Header = ({isOpenMenu,setIsOpenMenu}) => {

    return (
        <>
            <header className="bg-white sticky top-0 w-full z-20">
                <div className="p-4 flex gap-x-2 shadow-md">
                    <button onClick={() => setIsOpenMenu(!isOpenMenu)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                    <div>
                        هنرستان <span className="text-red-500">شهید</span> عابدینی
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header