import { Link, NavLink, useNavigate } from "react-router-dom"
import Image2 from "../../images/login-title.jpg"
import axios from "axios"
import { useContext, useEffect } from "react"
import { LoginContext } from "../Context/LoginContext"

const Menu = ({isOpenMenu,setIsOpenMenu,verify}) => {

    const navigator = useNavigate()
    const {setIsLogin} = useContext(LoginContext)

    const handleLogout = () => {
        axios.post(`${process.env.REACT_APP_AddressIP}/api/student/logout`,{},{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            localStorage.removeItem('token')
            setIsLogin(false)
            navigator('/')
        })
    }

    return (
        <>
            <div onClick={() => setIsOpenMenu(false)} className={`${isOpenMenu ? 'block' : 'hidden'} z-30 fixed top-0 left-0 backdrop-blur-sm w-full h-full flex justify-center items-center`}></div>
            <div className="fixed top-0 w-full z-30">
                <div className="relative">
                    <div onClick={() => setIsOpenMenu(false)} className={`${isOpenMenu ? '' : 'hidden'} fixed blur top-0 left-0 bg-opacity-40 bg-gray-100 w-full h-full z-20`}>
                    </div>
                <div className={`bg-white w-64 shadow-lg transition-all duration-300 absolute h-screen overflow-hidden z-40 ${isOpenMenu ? 'translate-x-0' : 'translate-x-72'}`}>
                    <div className="flex flex-col justify-center items-center gap-y-2 mb-5">
                        <img src={Image2} className="w-full h-28  object-cover" alt="" />
                    </div>
                    <div className="p-4 flex flex-col gap-y-4">
                        {!verify &&
                            <Link to={"/"} className="flex items-center gap-x-2 justify-center bg-purple-700 px-4 py-2 rounded-xl text-white">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </span>
                                <span className="text-sm">ویرایش</span>
                            </Link>
                        }
                        {verify ?
                            <>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={"/dashboard/disciplinarycases"} className={({isActive}) => isActive ? 'lg:cursor-pointer cursor-default flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default flex gap-x-2 items-center px-4 py-2 lg:cursor-pointer'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                                        </svg>
                                    </span>
                                    <span className="text-sm">موارد انضباطی</span>
                                </NavLink>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={"/dashboard/news"} className={({isActive}) => isActive ? 'lg:cursor-pointer cursor-default flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default flex gap-x-2 items-center px-4 py-2 lg:cursor-pointer'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                                        </svg>
                                    </span>
                                    <span className="text-sm">اخبار</span>
                                </NavLink>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={"/dashboard/chats"} className={({isActive}) => isActive ? 'lg:cursor-pointer cursor-default flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default flex gap-x-2 items-center px-4 py-2 lg:cursor-pointer'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                                        </svg>
                                    </span>
                                    <span className="text-sm">پیام ها</span>
                                </NavLink>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={"/dashboard/selectunit"} className={({isActive}) => isActive ? 'lg:cursor-pointer cursor-default flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default flex gap-x-2 items-center px-4 py-2 lg:cursor-pointer'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                        </svg>
                                    </span>
                                    <span className="text-sm">انتخاب واحد</span>
                                </NavLink>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={"/dashboard/protests"} className={({isActive}) => isActive ? 'lg:cursor-pointer cursor-default flex gap-x-2 items-center px-4 py-2 rounded-xl bg-slate-200' :'cursor-default flex gap-x-2 items-center px-4 py-2 lg:cursor-pointer'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg>
                                    </span>
                                    <span className="text-sm">اعتراضات</span>
                                </NavLink>
                            </>
                        : ''}
                        <button onClick={handleLogout} className="flex items-center cursor-default lg:cursor-pointer justify-center gap-x-2 bg-red-600 px-4 py-2 rounded-xl text-white">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                </svg>
                            </span>
                            <span className="text-sm">خروج</span>
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default Menu