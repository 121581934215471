import Swal from "sweetalert2"

const Toast = (type,data) => {
    switch(type){
        case 'success':
            const ToastSuccess = Swal.mixin({
                toast: true,
                background : '#afffcc',
                iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-500"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',                    
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                customClass : {
                    icon : 'border-0'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            ToastSuccess.fire({
                title: data.data.message
              })
        break;
        case 'error':
            const ToastError = Swal.mixin({
                toast: true,
                background : '#ef4444',
                position: 'top-end',
                iconHtml:'<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" /></svg>',
                color : 'white',
                showConfirmButton: false,
                timer: 3000,
                customClass:{
                    icon:'border-0'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
              
            ToastError.fire({
                title: data.response ? data.response.data.message : 'خطا در برقراری ارتباط'
              })
        break;
        default:

    }
}

export default Toast