import { Link } from "react-router-dom"
import Paginate from "../../components/Paginate"
import { useEffect, useState } from "react"
import axios from "axios"

const News = () => {

    const [news,setNews] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/all-news`)
        .then(data => setNews(data.data.news))
    },[])

    return (
        <>
            <div className=" z-30 sticky top-14 w-full border-t shadow-sm right-0">
                <div className="bg-slate-50 flex justify-center items-center p-1 gap-x-1 px-4 my-0 w-full ">
                    <div className="flex items-center gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                        </svg>
                    </div>
                    <span>اخبار</span>
                </div>
            </div>  
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 gap-y-4 py-5 px-2">
                {news && news.map(news => (
                    <Link key={news.id} to={`/detailnews/${news.id}`} className="cursor-default lg:cursor-pointer bg-white rounded-lg shadow-md border border-gray-300">
                        <div className="flex items-center">
                            <div className="w-5/12">
                                <img src={news.pic} className="w-44 h-28 object-cover rounded-r-lg border-l border-gray-300" alt="" />
                            </div>
                            <div className="p-2 flex flex-col gap-y-1 w-9/12">
                                <div className="text-sm text-blue-950">{news.title}</div>
                                <div className="text-xs text-gray-600 break-words">{news.content.slice(0,100)}...</div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="flex justify-center w-full pb-5 mb-16 lg:mb-0">
                <Paginate />
            </div>
        </>
    )
}

export default News