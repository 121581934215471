import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { AdminContext } from "../../../components/Context/AdminContext"
import Toast from "../../../components/Toast"

const CreateProtest = () => {
    const [lesson,setLesson] = useState(0)
    const [chapter,setChapter] = useState(0)
    const [score,setScore] = useState(null)
    const [description,setDescription] = useState(null)
    const [lessons,setLessons] = useState()
    const [loading,setLoading] = useState(true)
    const [errors,setErrors] = useState({
        protest_description : null,
        protest_score : null,
        protest_lesson : null
    })
    const [students,setStudents] = useState()
    const [studentId,setStudentId] = useState(0)
    const navigate = useNavigate()
    const {adminPath} = useContext(AdminContext)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/get-lessons`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => setLessons(data.data.lessons))
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/management-users`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(data => setStudents(data.data.students))
    },[])

    const handleLesson = (e) => {
        setChapter(0)
        setLesson(JSON.parse(e.target.value))
    }

    const handleProtest = () => {
        setErrors({
            protest_description : null,
            protest_score : null,
            protest_lesson : null
        })
        setLoading(true)
        if(!lesson)
        {
            setErrors((old) => ({...old,protest_lesson : 'فیلد درس اجباری است'}))
            setLoading(false)
        }
        if(lesson!= null && (lesson.is_chapter === 0 || chapter !== 0)){
        axios.post(`${process.env.REACT_APP_AddressIP}/api/admin/protests`,{
            course_id:lesson.id,
            student_id:studentId,
            protest_description : description,
            chapter,
            protest_score:score
        },{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(data => {
            if(data.data.code === 200)
            {
                setChapter(null) 
                setDescription(null)
                setScore(null)
                setLesson(0)
                setStudentId(0)
                Toast('success',data)
                navigate(`/${adminPath}/protests`)
            }
        }).catch(e => {
            if(e.response.status === 403)
                {
                    Toast('error',e)
                      setLoading(false)
                }
            if(e.response.status === 422){
                setErrors((old) => ({...old,...e.response.data.errors}))
                setLoading(false)
            }    
        })
        }
    }

    return (
        <div className="p-4 flex justify-center">
            <div className="bg-white shadow rounded-xl w-full flex flex-col gap-y-4 p-4 lg:w-5/12">
                        <div>
                            <select onChange={(e) => handleLesson(e)} className={`${errors.protest_lesson ? 'border-red-600' : 'border-gray-300'} border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}>
                                <option value="0">درس</option>
                                {lessons && lessons.map(lesson => (
                                    <option key={lesson.id} value={JSON.stringify(lesson)}>{lesson.lesson_name}</option>
                                ))}
                            </select>
                            {errors.protest_lesson &&
                                <div className="text-red-600 text-sm flex gap-x-1 mt-1">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>{errors.protest_lesson}</span>
                                </div>
                            }
                        </div>
                        {
                        lesson && lesson.is_chapter ?
                            <div className="border mb-5 border-gray-300 overflow-hidden shadow-sm flex justify-evenly rounded-md text-gray-500 text-sm ">
                                <button className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter === 1 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(1)}>1</button>
                                <button className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter === 2 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(2)}>2</button>
                                <button className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter === 3 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(3)}>3</button>
                                <button className={`text-center cursor-default w-3/12 py-1.5 border-l ${chapter === 4 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(4)}>4</button>
                                <button className={`text-center cursor-default w-3/12 py-1.5  ${chapter === 5 ? 'bg-sky-400 text-white' : ''}`} onClick={() => setChapter(5)}>5</button>
                            </div>
                            :''
                        }
                        <select onChange={(e) => setStudentId(e.target.value)} className="bg-white border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 focus:ring-indigo-500 rounded-md shadow-sm mt-1 block w-full">
                            <option value={"0"}>انتخاب دانش آموز</option>
                            {students && students.map(student => (
                                <option key={student.id} value={student.id}>{student.first_name+' '+student.last_name+' '+student.nation_code}</option>
                                ))}
                        </select>
                        <div>
                            <input type="number" value={score == null ? '' : score} placeholder="نمره" onChange={(e) => setScore(e.target.value)} className={`${errors.protest_score ? 'border-red-600' : 'border-gray-300'} border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                        </div>
                        {errors.protest_score &&
                            <div className="text-red-600 text-sm flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.protest_score}</span>
                            </div>
                        }
                        <textarea value={description == null ? '' : description} onChange={(e) => setDescription(e.target.value)} className={`${errors.protest_description ? 'border-red-600' : 'border-gray-300'} border outline-none py-1 px-2 text-sm h-20 resize-none text-gray-500 focus:border-sky-400 rounded-md shadow-sm mt-1 block w-full`} placeholder="توضیحات..."></textarea>
                        {errors.protest_description &&
                            <div className="text-red-600 text-sm flex gap-x-1">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <span>{errors.protest_description}</span>
                            </div>
                        }
                        <div className="flex justify-center">
                            <button onClick={handleProtest} className="cursor-default lg:cursor-pointer bg-sky-400 text-white rounded-md shadow hover:bg-sky-600 px-4 py-2">ثبت اعتراض</button>
                        </div>
                        
                    </div>
        </div>
    )
}

export default CreateProtest