import { Link, NavLink, useNavigate } from "react-router-dom"
import Image2 from "../../images/login-title.jpg"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { LoginContext } from "../Context/LoginContext"
import { AdminContext } from "../Context/AdminContext"

const Menu = ({isOpenMenu,setIsOpenMenu}) => {

    const navigator = useNavigate()
    const {setIsLogin} = useContext(LoginContext)
    const [activeMenu,setActiveMenu] = useState()
    const {adminPath} = useContext(AdminContext)

    const handleLogout = () => {
        axios.post(`${process.env.REACT_APP_AddressIP}/api/student/logout`,{},{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            localStorage.removeItem('token')
            setIsLogin(false)
            navigator('/')
        })
    }

    const handleMenu = (menuId) => {
        setActiveMenu((old) => old === menuId ? 0 : menuId)
    }

    return (
        <>
            <div className={`${isOpenMenu ? 'block' : 'hidden'} z-50 fixed top-0 left-0 backdrop-blur-sm w-full h-full flex justify-center items-center`}></div>
            <div className="fixed top-0 w-full z-50">
                <div className="relative">
                    <div onClick={() => setIsOpenMenu(false)} className={`${isOpenMenu ? '' : 'hidden'} fixed top-0 left-0 bg-opacity-40 bg-gray-100 w-full h-full z-20`}>
                    </div>
                <div className={`bg-white w-64 shadow-lg transition-all duration-300 absolute h-screen overflow-hidden z-40 ${isOpenMenu ? 'translate-x-0' : 'translate-x-72'}`}>
                    <div className="flex flex-col justify-center items-center gap-y-2 mb-5">
                        <img src={Image2} className="w-full h-28  object-cover" alt="" />
                    </div>
                    <div className="p-4 px-0 flex flex-col">
                        <NavLink onClick={() => setIsOpenMenu(false)} to={`/${adminPath}/news`} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 bg-slate-200 border-b border-dashed' :'cursor-default flex lg:cursor-pointer gap-x-2 items-center border-b border-dashed px-4 py-2 '}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                                </svg>
                            </span>
                            <span className="text-sm">اخبار</span>
                        </NavLink>
                        <NavLink onClick={() => setIsOpenMenu(false)} to={"/dashboard/chats"} className={({isActive}) => isActive ? 'cursor-default lg:cursor-pointer flex gap-x-2 items-center px-4 py-2 bg-slate-200 border-b border-dashed' :'cursor-default flex lg:cursor-pointer gap-x-2 items-center border-b border-dashed px-4 py-2'}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                </svg>
                            </span>
                            <span className="text-sm">رشته ها</span>
                        </NavLink>
                        <div>
                            <button onClick={() => handleMenu(10)} className='cursor-default lg:cursor-pointer flex justify-between w-full items-center px-4 py-2 border-b border-dashed'>
                                <div className="flex gap-x-2">
                                    <span>
                                        <img src="/icons/student.png" className="w-5 h-5" alt="" />
                                    </span>
                                    <span className="text-sm">دانش آموزان</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${activeMenu === 10 ? 'rotate-180' : ''} transition-all duration-300 w-5 h-5`}>
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </button>
                            <div className={`${activeMenu === 10 ? 'block' : 'hidden'} px-10 text-sm flex flex-col gap-y-4 bg-gray-100 py-4 shadow-inner`}>
                                <NavLink to={`/${adminPath}/student/create`} onClick={() => setIsOpenMenu(false)} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>ایجاد</span>
                                </NavLink>
                                <NavLink to={`/${adminPath}/students`} onClick={() => setIsOpenMenu(false)} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>لیست</span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => handleMenu(11)} className='cursor-default lg:cursor-pointer flex justify-between w-full items-center px-4 py-2 border-b border-dashed'>
                                <div className="flex gap-x-2">
                                    <span>
                                        <img src="/icons/staff.png" className="w-5 h-5" alt="" />
                                    </span>
                                    <span className="text-sm">کارکنان</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${activeMenu === 11 ? 'rotate-180' : ''} transition-all duration-300 w-5 h-5`}>
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </button>
                            <div className={`${activeMenu === 11 ? 'block' : 'hidden'} px-10 text-sm flex flex-col gap-y-4 bg-gray-100 py-4 shadow-inner`}>
                                <NavLink to={`/${adminPath}/staff/create`} onClick={() => setIsOpenMenu(false)} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>ایجاد</span>
                                </NavLink>
                                <NavLink to={`/${adminPath}/staffs`} onClick={() => setIsOpenMenu(false)} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>لیست</span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => handleMenu(2)} className='cursor-default lg:cursor-pointer flex justify-between w-full items-center px-4 py-2 border-b border-dashed'>
                                <div className="flex gap-x-2">
                                    <span>
                                        <img src="/icons/staff.png" className="w-5 h-5" alt="" />
                                    </span>
                                    <span className="text-sm">مدیران</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${activeMenu === 2 ? 'rotate-180' : ''} transition-all duration-300 w-5 h-5`}>
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </button>
                            <div className={`${activeMenu === 2 ? 'block' : 'hidden'} px-10 text-sm flex flex-col gap-y-4 bg-gray-100 py-4 shadow-inner`}>
                                <NavLink to={`/${adminPath}/role/create`} onClick={() => setIsOpenMenu(false)} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>سطح دسترسی</span>
                                </NavLink>
                                <NavLink to={`/${adminPath}/roles`} onClick={() => setIsOpenMenu(false)} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>لیست</span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => handleMenu(3)} className='cursor-default lg:cursor-pointer flex justify-between w-full items-center px-4 py-2 border-b border-dashed'>
                                <div className="flex gap-x-2">
                                    <span>
                                        <img src="/icons/disciplinary.png" className="w-5 h-5" alt="" />
                                    </span>
                                    <span className="text-sm">انضباط</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${activeMenu === 3 ? 'rotate-180' : ''} transition-all duration-300 w-5 h-5`}>
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </button>
                            <div className={`${activeMenu === 3 ? 'block' : 'hidden'} px-10 text-sm flex flex-col gap-y-4 bg-gray-100 py-4 shadow-inner`}>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={`/${adminPath}/discipline/create`}  className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>ثبت</span>
                                </NavLink>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={`/${adminPath}/disciplines`} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>مشاهده و ویرایش</span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => handleMenu(4)} className='cursor-default lg:cursor-pointer flex justify-between w-full items-center px-4 py-2 border-b border-dashed'>
                                <div className="flex gap-x-2">
                                    <span>
                                        <img src="/icons/protest.png" className="w-5 h-5" alt="" />
                                    </span>
                                    <span className="text-sm">اعتراضات</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${activeMenu === 4 ? 'rotate-180' : ''} transition-all duration-300 w-5 h-5`}>
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </button>
                            <div className={`${activeMenu === 4 ? 'block' : 'hidden'} px-10 text-sm flex flex-col gap-y-4 bg-gray-100 py-4 shadow-inner`}>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={`/${adminPath}/protest/create`} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>ثبت</span>
                                </NavLink>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={`/${adminPath}/protests`} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>مشاهده و ویرایش</span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => handleMenu(5)} className='cursor-default lg:cursor-pointer flex justify-between w-full items-center px-4 py-2 border-b border-dashed'>
                                <div className="flex gap-x-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                                        </svg>
                                    </span>
                                    <span className="text-sm">انتخاب واحد</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${activeMenu === 5 ? 'rotate-180' : ''} transition-all duration-300 w-5 h-5`}>
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </button>
                            <div className={`${activeMenu === 5 ? 'block' : 'hidden'} px-10 text-sm flex flex-col gap-y-4 bg-gray-100 py-4 shadow-inner`}>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={`/${adminPath}/selectunit/create`} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>ثبت</span>
                                </NavLink>
                                <NavLink onClick={() => setIsOpenMenu(false)} to={`/${adminPath}/selectunit`} className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>مشاهده و چاپ</span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => handleMenu(6)} className='cursor-default lg:cursor-pointer flex justify-between w-full items-center px-4 py-2 border-b border-dashed'>
                                <div className="flex gap-x-2">
                                    <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-5 h-5">
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                            />
                                        </svg>
                                    </span>
                                    <span className="text-sm">پیام ها</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${activeMenu === 6 ? 'rotate-180' : ''} transition-all duration-300 w-5 h-5`}>
                                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </button>
                            <div className={`${activeMenu === 6 ? 'block' : 'hidden'} px-10 text-sm flex flex-col gap-y-4 bg-gray-100 py-4 shadow-inner`}>
                                <NavLink className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>گروه بندی</span>
                                </NavLink>
                                <NavLink className={({isActive}) => isActive ? 'flex gap-x-1' : 'flex gap-x-1'}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span>ارسال پیام</span>
                                </NavLink>
                            </div>
                        </div>
                        <NavLink onClick={() => setIsOpenMenu(false)} to={`/${adminPath}/setting`} className={({isActive}) => isActive ? 'cursor-default flex gap-x-2 items-center px-4 py-2 bg-slate-200 border-b border-dashed' :'cursor-default flex lg:cursor-pointer gap-x-2 items-center border-b border-dashed px-4 py-2 '}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </span>
                            <span className="text-sm">تنظیمات</span>
                        </NavLink>
                        <div onClick={handleLogout} className="flex lg:cursor-pointer items-center justify-center gap-x-2 bg-red-600 px-4 py-2 text-white">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                </svg>
                            </span>
                            <span className="text-sm">خروج</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default Menu