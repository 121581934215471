import { useEffect } from "react"
import { useState } from "react"

const Calendar = ({setFields,fields,field}) => {
    const [active,setActive] = useState({
        year : false,
        month : false,
        day : false,
    })
    const [years,setYears] = useState([])
    const [days,setDays] = useState([])
    const [birthday,setBirthday] = useState({
        year : 0,
        month : 0,
        day : 0
    })
    useEffect(() => {
        if(fields){
            const birthday = fields[field].split('/');
            setBirthday({
                year:birthday[0],
                month:birthday[1],
                day:birthday[2]
            })
            
        }
        for (let i = 1402;i>=1330;i--)
        {
            setYears((oldArray) => [...oldArray,i])
        }
    },[])

    const handleSetDays = (days) => {
        setDays([])
        for (let i = 1;i<=days;i++)
        {
            setDays((oldArray) => [...oldArray,i])
        }
    }

    const handleCompleteDate = (e) => {
        if(e.key === '+')
        {
            if(birthday.day)
            {}
        }
    }

    return (
        <>
            <div className={`bg-white border absolute top-5 shadow-xl  ${active.year ? 'block' : 'hidden'}`}>
                <div className="flex flex-col h-56 overflow-hidden overflow-y-scroll items-center ">
                    {years.map(year => (
                        <div key={year} className="hover:bg-blue-500 px-10 cursor-pointer"  onClick={() => {
                            setBirthday({...birthday,year : year})
                            setActive({...active,month : true,year : false})
                        }}>
                            {year}
                        </div>
                    ))}
                </div>
            </div>
            <div className={`bg-white border absolute top-5 shadow-xl  ${active.month ? 'block' : 'hidden'}`}>
                <div className="grid grid-cols-3 h-56 items-center justify-items-center">
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 1})
                        handleSetDays(31)
                        setActive({...active,day : true,month : false})
                    }}>فروردین</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 2})
                        handleSetDays(31)
                        setActive({...active,day : true,month : false})
                    }}>اردیبهشت</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 3})
                        handleSetDays(31)
                        setActive({...active,day : true,month : false})
                    }}>خرداد</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 4})
                        handleSetDays(31)
                        setActive({...active,day : true,month : false})
                    }}>تیر</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 5})
                        handleSetDays(31)
                        setActive({...active,day : true,month : false})
                    }}>مرداد</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 6})
                        handleSetDays(31)
                        setActive({...active,day : true,month : false})
                    }}>شهریور</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 7})
                        handleSetDays(30)
                        setActive({...active,day : true,month : false})
                    }}>مهر</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 8})
                        handleSetDays(30)
                        setActive({...active,day : true,month : false})
                    }}>آبان</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 9})
                        handleSetDays(30)
                        setActive({...active,day : true,month : false})
                    }}>آذر</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 10})
                        handleSetDays(30)
                        setActive({...active,day : true,month : false})
                    }}>دی</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 11})
                        handleSetDays(30)
                        setActive({...active,day : true,month : false})
                    }}>بهمن</div>
                    <div className="cursor-pointer hover:bg-blue-500 px-2 py-4 w-full text-center" onClick={() => {
                        setBirthday({...birthday,month : 12})
                        handleSetDays(29)
                        setActive({...active,day : true,month : false})
                    }}>اسفند</div>
                </div>
            </div>
            <div className={`bg-white border absolute top-5 shadow-xl  ${active.day ? 'block' : 'hidden'}`}>
                <div className="grid grid-cols-6   ">
                    {days.map(day => (
                        <div className="hover:bg-blue-500 px-4 cursor-pointer py-2 text-center w-full" key={day} onClick={() => {
                            setBirthday({...birthday,day : day})
                            setActive({...active,day : false})
                            setFields((prev) => ({...prev,[field] : `${birthday.year}/${birthday.month}/${day}`}))
                        }}>
                            {day}
                        </div>
                    ))}
                </div>
            </div>
            <input onKeyUp={(e) => handleCompleteDate(e)} type="text" value={`${birthday.year}/${birthday.month}/${birthday.day}`} onClick={() => setActive({year : true})} className="border border-gray-300 w-full outline-none px-4 py-2 rounded-md"/>
        </>
    )
}

export default Calendar