import { useEffect } from "react"

const ErorrForm = ({error,group,setErrorStudentInformation,setErrorEducationInformation,setErrorContactInformation,setErrorFamilyInformation,setErrorUserInformation}) => {

    useEffect(() => {
        if (error){
            switch (group){
                case 'studentInformation': {
                    setErrorStudentInformation((prevState) => prevState + 1)
                    console.log(error);
                    break
                } 
                case 'educationInformation': {
                    setErrorEducationInformation((prevState) => prevState + 1)
                    break
                } 
                case 'contactInformation': {
                    setErrorContactInformation((prevState) => prevState + 1)
                    break
                } 
                case 'familyInformation': {
                    setErrorFamilyInformation((prevState) => prevState + 1)
                    break
                } 
                case 'userInformation': {
                    setErrorUserInformation((prevState) => prevState + 1)
                }   
            }
        }
    },[error])
    return (
        <>
            {error && 
            <div className="text-red-600 text-sm mt-2 w-full flex gap-x-1">
                <span>
                    <i className="fa-solid fa-circle-exclamation"></i>
                </span>
                <span>{error}</span>
            </div>
            }
        </>
    )
}

export default ErorrForm