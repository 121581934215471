import axios from "axios"
import { useContext, useEffect } from "react"
import { useState } from "react"
import { AdminContext } from '../../../components/Context/AdminContext'
import { useNavigate } from "react-router"
import Toast from "../../../components/Toast"


const CreateDiscipline = () => {

    const [students,setStudents] = useState([])
    const [studentId,setStudentId] = useState(null)
    const [title,setTitle] = useState(null)
    const [description,setDescription] = useState(null)
    const [disType,setDisType] = useState(null)
    const [decision,setDecision] = useState(null)
    const [score,setScore] = useState(null)
    const {adminPath} = useContext(AdminContext)
    const navigate = useNavigate()
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AddressIP}/api/admin/management-users`,{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(data => {
            if(data.request.status === 200)
            {
                setStudents(data.data.students)
            }
        })
    },[])

    const handleSubmit = () => {
        axios.post(`${process.env.REACT_APP_AddressIP}/api/admin/disciplines`,{
            student_id : studentId,
            title,
            description,
            dis_type : disType,
            decision,
            score,
        },{
            headers : {
                Authorization : `Bearer ${localStorage.getItem('token')}`
            }
        }).then(data => {
            if(data.request.status===200){
                Toast('success',data)
                navigate(`/${adminPath}/disciplines`)
            }
        })
    }

    return (
        <div className="p-4 flex justify-center">
            <div className="bg-white shadow rounded-xl w-full flex flex-col gap-y-4 p-4 lg:w-5/12">
                <select onChange={(e) => setStudentId(e.target.value)} className="bg-white border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 focus:ring-indigo-500 rounded-md shadow-sm mt-1 block w-full">
                    <option>انتخاب دانش آموز</option>
                    {students && students.map(student => (
                        <option value={student.id} key={student.id}>{student.first_name+' '+student.last_name+' '+student.nation_code}</option>
                        ))}
                </select>
                <div>
                    <input type="text" placeholder="عنوان" onChange={(e) => setTitle(e.target.value)} className={`border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                </div>
                <div>
                    <input type="text" placeholder="توضیحات" onChange={(e) => setDescription(e.target.value)} className={`border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                </div>
                <select onChange={(e) => setDisType(e.target.value)} className="bg-white border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 focus:ring-indigo-500 rounded-md shadow-sm mt-1 block w-full">
                    <option>نوع انضباط</option>
                    <option value={1}>تشویفی</option>
                    <option value={2}>مورد انضباطی</option>
                    <option value={3}>غیبت</option>
                </select>
                <select onChange={(e) => setDecision(e.target.value)} className="bg-white border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 focus:ring-indigo-500 rounded-md shadow-sm mt-1 block w-full">
                    <option>تصمیم اتخاذ شده</option>
                    <option value={0}>نمره تشویفی</option>
                    <option value={1}>کسر نمره</option>
                </select>
                <div>
                    <input type="number" placeholder="نمره" onChange={(e) => setScore(e.target.value)} className={`border-gray-300 border outline-none py-1 px-2 text-sm text-gray-500 focus:border-sky-400 bg-white rounded-md shadow-sm mt-1 block w-full`}/>
                </div>
                <div className="flex justify-center">
                    <button onClick={() => handleSubmit()} className="cursor-default lg:cursor-pointer bg-sky-400 px-4 py-2 rounded-md text-white shadow hover:bg-sky-600">ثبت</button>
                </div>
            </div>
        </div>
    )
}

export default CreateDiscipline