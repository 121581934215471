import { Route, Routes, useNavigate } from "react-router"
import Header from "../components/Main/Header"
import { useContext, useEffect, useState } from "react"
import Padding from "../components/Padding"
import Menu from "../components/Admin/Menu"
import Login from "../pages/Admin/Login"
import Home from "../pages/Admin/Home"
import MobileMenu from "../components/Admin/MobileMenu"
import IndexNews from "../pages/Admin/News/Index"
import EditNews from "../pages/Admin/News/Edit"
import CreateStudent from "../pages/Admin/Student/Create"
import IndexStudent from "../pages/Admin/Student/Index"
import EditStudent from "../pages/Admin/Student/Edit"
import NotFound from "../pages/Admin/NotFound"
import { AdminContext } from "../components/Context/AdminContext"
import { LoginContext } from "../components/Context/LoginContext"
import reactManifest from "react-manifest"
import axios from "axios"
import IndexStaff from "../pages/Admin/Staff/Index"
import CreateStaff from "../pages/Admin/Staff/Create"
import EditStaff from "../pages/Admin/Staff/Edit"
import IndexSelectUnit from "../pages/Admin/SelectUnit/Index"
import CreateSelectUnit from "../pages/Admin/SelectUnit/Create"
import CreateProtest from "../pages/Admin/Protest/Create"
import IndexProtest from "../pages/Admin/Protest/Index"
import CreateDiscipline from "../pages/Admin/Discipline/Create"
import Setting from "../pages/Admin/Setting"
import IndexDiscipline from "../pages/Admin/Discipline/Index"
import EditDiscipline from "../pages/Admin/Discipline/Edit"
import CreateRole from "../pages/Admin/Role/CreateRole"
import EditRole from "../pages/Admin/Role/EditRole"
import IndexRole from "../pages/Admin/Role/IndexRole"

const Admin = () => {
    const [isOpenMenu,setIsOpenMenu] = useState(false)
    const {isLogin} = useContext(LoginContext)
    const {adminPath} = useContext(AdminContext)
    const navigate = useNavigate()
    
    
    useEffect(() => {
        if(!isLogin){
            navigate(`/${adminPath}/login`)
        }
    },[])

    return (
        <>
            <Menu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu}/>
            <Padding className={`z-40 fixed bottom-0 w-full h-full overflow-y-scroll lg:overflow-y-auto`}>
                <Header isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu}/>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/news" element={<IndexNews />} />
                        <Route path="/news/:id" element={<EditNews />}/>
                        <Route path="/student/create" element={<CreateStudent />} />
                        <Route path="/students" element={<IndexStudent />} />
                        <Route path="/student/:id/edit" element={<EditStudent />} />
                        <Route path="/staffs" element={<IndexStaff />} />
                        <Route path="/staff/create" element={<CreateStaff />} />
                        <Route path="/staff/:id/edit" element={<EditStaff />} />
                        <Route path="/selectunit" element={<IndexSelectUnit />} />
                        <Route path="/selectunit/create" element={<CreateSelectUnit />} />
                        <Route path="/protest/create" element={<CreateProtest />} />
                        <Route path="/protests" element={<IndexProtest />} />
                        <Route path="/setting" element={<Setting />} />
                        <Route path="/discipline/create" element={<CreateDiscipline />} />
                        <Route path="/disciplines" element={<IndexDiscipline />} />
                        <Route path="/discipline/:id/edit" element={<EditDiscipline />} />
                        <Route path="/role/create" element={<CreateRole />} />
                        <Route path="/roles" element={<IndexRole />} />
                        <Route path="/role/:id/edit" element={<EditRole />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <MobileMenu />
            </Padding>
        </>
    )
}

export default Admin